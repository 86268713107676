import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modal[nz-modal]',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() title: string;

  @Input() closeable: boolean;
  @Input() width: string | number;
  constructor() { }

  ngOnInit() {
  }

}
