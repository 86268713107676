import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { ApexOptionsChart } from '@data/apexOptionsChart';

@Component({
  selector: 'brandSentiment',
  templateUrl: './brandSentiment.component.html',
  styleUrls: ['./brandSentiment.component.scss'],
})
export class BrandSentimentComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ApexOptionsChart>;

  @Input() props = {
    series: [],
  };

  constructor() {
    this.chartOptions = {
      chart: {
        width: 240,
        height: 240,
        type: 'donut',
      },
      series: this.props.series,
      colors: ['#06d6a0', '#00bbf9', '#ff6b6b'],
      labels: ['positive', 'neutral', 'negative'],
      plotOptions: {
        pie: {
          startAngle: 0,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: '65%',
            background: 'transparent',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Lato, sans-serif',
                fontWeight: 600,
                color: '#edf2f4',
                offsetY: 0,
                formatter: function (val) {
                  return val;
                },
              },
              value: {
                show: false,
                fontSize: '16px',
                fontFamily: 'Lato, sans-serif',
                fontWeight: 400,
                color: '#edf2f4',
                offsetY: 0,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                showAlways: true,
                label: 'Brand Name',
                fontSize: '15px',
                fontFamily: 'Kanit, sans-serif',
                fontWeight: 'normal',
                color: '#edf2f4',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      stroke: {
        width: 4,
        colors: ['#36414c'],
      },
      legend: {
        show: false,
        position: 'bottom',
        labels: {
          colors: '#edf2f4',
          useSeriesColors: false,
        },

        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#36414c',
          fillColors: ['#06d6a0', '#00bbf9', '#ff6b6b'],
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },

        itemMargin: {
          horizontal: 3,
          vertical: 0,
        },
        tooltipHoverFormatter: function (val, opts) {
          return val;
        },
      },

      dataLabels: {
        enabled: false,
      },

      fill: {
        type: 'solid',
        opacity: 1,
        colors: ['#06d6a0', '#00bbf9', '#ff6b6b'],
      },

      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: false,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: true,
        fillColors: '#36414c',
        style: {
          fontSize: '14px',
          fontFamily: 'Lato, sans-serif',
          color: '#36414c',
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: true,
          format: 'dd MMM',
          formatter: undefined,
        },
        y: {
          formatter: undefined,
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        z: {
          formatter: undefined,
          title: 'Size: ',
        },
        marker: {
          show: true,
          fillSeriesColor: ['#06d6a0', '#00bbf9', '#ff6b6b'],
        },
        items: {
          display: 'flex',
        },
        fixed: {
          enabled: false,
        },
      },
      xaxis: {
        type: 'category',
      },

      responsive: [
        {
          breakpoint: 1025,
          options: {
            chart: {
              width: 150,
              height: 150,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    total: {
                      fontSize: 12,
                    },
                  },
                },
              },
            },
          },
        },
      ],
    };
  }

  ngOnInit() {}
}
