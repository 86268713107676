export const positiveList = [
  {
    title: 'lorem ipsum',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, enim!',
    opinion: 200,
    posValue: 100,
    negValue: 20,
  },
  {
    title: 'lorem ipsum',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, enim!',
    opinion: 200,
    posValue: 100,
    negValue: 20,
  },
  {
    title: 'lorem ipsum',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, enim!',
    opinion: 200,
    posValue: 100,
    negValue: 20,
  },
  {
    title: 'lorem ipsum',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, enim!',
    opinion: 200,
    posValue: 100,
    negValue: 20,
  },
  {
    title: 'lorem ipsum',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, enim!',
    opinion: 200,
    posValue: 100,
    negValue: 20,
  },
  {
    title: 'lorem ipsum',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, enim!',
    opinion: 200,
    posValue: 100,
    negValue: 20,
  },
  {
    title: 'lorem ipsum',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, enim!',
    opinion: 200,
    posValue: 100,
    negValue: 20,
  },
  {
    title: 'lorem ipsum',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, enim!',
    opinion: 200,
    posValue: 100,
    negValue: 20,
  },
  {
    title: 'lorem ipsum',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, enim!',
    opinion: 200,
    posValue: 100,
    negValue: 20,
  },
  {
    title: 'lorem ipsum',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, enim!',
    opinion: 200,
    posValue: 100,
    negValue: 20,
  },
];

export const mediaList = [
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
  {
    title: 'lorem ipsum',
    media: 'metro tv',
    date: '01/01/2020',
  },
];
