export const mockSeries1 = [50, 40, 10];
export const mockSeries2 = [70, 20, 10];
export const mockSeries3 = [50, 22, 28];
export const mockSeries4 = [80, 15, 5];
export const mockSeries5 = [35, 41, 14];
export const mockSeries6 = [25, 30, 45];

export const adValue = [
  {
    name: 'Bank Mandiri',
    total: 130,
    positive: 100,
    negative: 80
  },
  {
    name: 'Bank BCA',
    total: 320,
    positive: 100,
    negative: 80
  },
  {
    name: 'Bank BNI',
    total: 260,
    positive: 100,
    negative: 80
  },
  {
    name: 'Bank BTPN',
    total: 585,
    positive: 100,
    negative: 80
  }
];


export const DataSeries = {
  data1: [
    {
      x: "ABC",
      y: 10
    },
    {
      x: "DEF",
      y: 60
    },
    {
      x: "XYZ",
      y: 41
    }
  ],
  data2: [
    {
      x: "ABCD",
      y: 10
    },
    {
      x: "DEFG",
      y: 20
    },
    {
      x: "WXYZ",
      y: 51
    },
    {
      x: "PQR",
      y: 30
    },
    {
      x: "MNO",
      y: 20
    },
    {
      x: "CDE",
      y: 30
    }
  ],
};