<div class="loading-overlay-component">
  <div className="load-logo">
    <svg
      class="icon-load"
      width="1000"
      height="1000"
      viewBox="0 0 1079 282"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.97028 34.8868V15.8433H35.7215L1.97028 34.8868Z"
        stroke="#353f53"
        strokeWidth="2"
      />
      <path
        d="M94.1093 35.1964V16.1529H127.861L94.1093 35.1964Z"
        stroke="#353f53"
        strokeWidth="2"
      />
      <path
        d="M40.242 62.8942L4.48397 41.8061L40.242 21.2682V62.8942Z"
        stroke="#FF931E"
        strokeWidth="2"
      />
      <path
        d="M40.242 117.682L4.48397 96.5943L40.242 76.0564V117.682Z"
        stroke="#FF931E"
        strokeWidth="2"
      />
      <path
        d="M85.8472 89.9271L50.0892 68.8391L85.8472 48.3012V89.9271Z"
        stroke="#353f53"
        strokeWidth="2"
      />
      <path
        d="M85.8472 143.374L50.0892 122.286L85.8472 101.748V143.374Z"
        stroke="#77A1B6"
        strokeWidth="2"
      />
      <path
        d="M131.349 117.682L95.5912 96.5943L131.349 76.0564V117.682Z"
        stroke="#77A1B6"
        strokeWidth="2"
      />
      <path
        d="M94.1093 48.3358L129.867 69.4239L94.1093 89.9618V48.3358Z"
        stroke="#FF931E"
        strokeWidth="2"
      />
      <path
        d="M48.5041 21.3029L84.2621 42.3909L48.5041 62.9289V21.3029Z"
        stroke="#77A1B6"
        strokeWidth="2"
      />
      <path
        d="M2.27982 48.3358L38.0378 69.4239L2.27982 89.9618V48.3358Z"
        stroke="#77A1B6"
        strokeWidth="2"
      />
    </svg>
  </div>
</div>
