import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from '@services/header.service';

import { ChartComponent } from 'ng-apexcharts';
import { ApexOptionsChart } from '@data/apexOptionsChart';

import * as Highcharts from 'highcharts';
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);

import { positiveList } from '@core/mock/listData';
import { ContentService } from "@services/content.service";
import { environment } from "../../../environments/environment";
import { Subject, Subscription } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { takeUntil } from "rxjs/operators";
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-employee',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
    baseColor = '#e76f51';
    
    name = 'This is XLSX TO JSON CONVERTER';
    willDownload = false;
    @ViewChild('chart', { static: false }) chart: ChartComponent;
    public topicOptions: Partial<ApexOptionsChart>;
    filecount: number = 3;
    dataPositive = positiveList;

    Highcharts = Highcharts;
    feedbackOptions = {};
    subscription: Subscription;
    subject = webSocket("wss://ifap.digivla.com:8091");
    // subject = webSocket('wss://echo.websocket.org')
    destroy$: Subject<boolean> = new Subject<boolean>();
    showtopicmap: boolean = false;
    dataDelight = [];
    dataDelight2 = [];
    trigershowchart: boolean = false;
    downloadFc: FormControl = new FormControl()
    datacleanjson = []
    datacleanjsonpositive = []
    datacleanjsonnegative = []
    listname = [];
    constructor(private headerService: HeaderService, private contenSvc: ContentService) { }


    ngOnInit() {
        this.headerService.setTitle('employee engagament');
        this.headerService.setColor(this.baseColor);
        this.subject.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        });
    }

    onFileChange(ev) {
        let workBook = null;
        let jsonData = null;
        const reader = new FileReader();
        const file = ev.target.files[0];
        this.listname.push(file.name)
        reader.onload = (event) => {
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            const dataString = JSON.stringify(jsonData);
            if (dataString) {
                const datapushindex = []
                const datapushindexpositive = []
                const datapushindexnegative = []
                const dataallJson = JSON.parse(dataString).Summary
                dataallJson.forEach((element, index) => {
                    if (element['Sheet description']) {
                        if (element['Sheet description'] === 'TOP 10 labels') {
                            const datamaxloop = index + 12;
                            for (let i = index + 2; i < datamaxloop; i++) {
                                datapushindex.push(i)
                            }
                        }
                        if (element['Sheet description'] === 'TOP 5 most positive labels'){
                            const datamaxloop = index + 7;
                            for (let i = index + 2; i < datamaxloop; i++) {
                                datapushindexpositive.push(i)
                            }
                        }
                        if (element['Sheet description'] === 'TOP 5 most negative labels'){
                            const datamaxloop = index + 7;
                            for (let i = index + 2; i < datamaxloop; i++) {
                                datapushindexnegative.push(i)
                            }
                        }
                    }
                });
                datapushindex.forEach(item => {
                    const renameobj = { '__EMPTY': 'frequency_mention', '__EMPTY_1': 'frequency_rows', '__EMPTY_2': 'frequency_in_all_rows', '__EMPTY_3': 'opinion_index', '__EMPTY_4': 'opinion_index_value', '__EMPTY_5': 'example_mention', '__EMPTY_7': 'not_rename' }
                    const datarename = this.renameKeys(dataallJson[item], renameobj)
                    this.datacleanjson.push(datarename)
                })
                datapushindexpositive.forEach(item => {
                    const renameobj = { '__EMPTY': 'frequency_mention', '__EMPTY_1': 'frequency_rows', '__EMPTY_2': 'frequency_in_all_rows', '__EMPTY_3': 'opinion_index', '__EMPTY_4': 'opinion_index_value', '__EMPTY_5': 'example_mention', '__EMPTY_7': 'not_rename' }
                    const datarename = this.renameKeys(dataallJson[item], renameobj)
                    this.datacleanjsonpositive.push(datarename)
                })
                datapushindexnegative.forEach(item => {
                    const renameobj = { '__EMPTY': 'frequency_mention', '__EMPTY_1': 'frequency_rows', '__EMPTY_2': 'frequency_in_all_rows', '__EMPTY_3': 'opinion_index', '__EMPTY_4': 'opinion_index_value', '__EMPTY_5': 'example_mention', '__EMPTY_7': 'not_rename' }
                    const datarename = this.renameKeys(dataallJson[item], renameobj)
                    this.datacleanjsonnegative.push(datarename)
                })
                if (this.datacleanjson.length > 0) {
                    this.filecount--;
                    this.downloadFc.setValue('')
                    if (this.filecount === 0) {
                        this.trigershowchart = true;
                        this.dataDelight = []
                        this.loaddatazurvey2(this.datacleanjson.sort((a, b) => parseFloat(b.frequency_mention) - parseFloat(a.frequency_mention)).slice(0,10))
                        this.dataDelight.push(this.datacleanjsonpositive.sort((a, b) => parseFloat(b.frequency_mention) - parseFloat(a.frequency_mention)).slice(0,10)) 
                        this.dataDelight2.push(this.datacleanjsonnegative.sort((a, b) => parseFloat(b.frequency_mention) - parseFloat(a.frequency_mention)).slice(0,10)) 
                        this.loadMap(this.datacleanjson)
                    }
                }

            }
        }
        reader.readAsBinaryString(file);
    }
    renameKeys(obj, newKeys) {
        const keyValues = Object.keys(obj).map(key => {
            const newKey = newKeys[key] || key;
            return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    }
    loaddatazurvey2(data: any) {
        const datatempallfeedback2 = []
        data.forEach(element => {
            const obj = { data: [{ x: Number(element['frequency_mention']), y: Number(element['opinion_index']), z: 100, name: element['Sheet description'] }] }
            datatempallfeedback2.push(obj)
        });
        this.feedbackOptions = {
            colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#9b5de5', '#ff6392'],
            series: datatempallfeedback2,
            chart: {
                type: 'bubble',
                backgroundColor: null,
                style: {
                    fontFamily: 'Lato, sans-serif',
                },
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}',
                        style: {
                            fontFamily: 'Lato, sans-serif',
                            color: '#edf2f4',
                        },
                    },
                },
                bubble: {
                    displayNegative: true,
                    negativeColor: '#ff6b6b',
                    // minSize: 50,
                    // maxSize: 50,
                    // zMin: -200,
                    // zMax: 200,
                },
            },

            credits: {
                enabled: false,
            },

            exporting: {
                enabled: false,
            },

            legend: {
                enabled: false,
            },

            title: {
                text: null,
            },

            xAxis: {
                gridLineWidth: 1,
                gridLineDashStyle: 'longdash',
                // min: 0,
                // max: 200,
                title: {
                    text: 'Mention Number',
                    style: {
                        fontFamily: 'Lato, sans-serif',
                        color: '#e76f51',
                    },
                },
                labels: {
                    format: '{value}',
                    style: {
                        fontFamily: 'Lato, sans-serif',
                        color: '#edf2f4',
                    },
                },
            },

            yAxis: {
                startOnTick: false,
                endOnTick: false,
                gridLineWidth: 0,
                // min: -200,
                // max: 200,
                title: {
                    text: 'Opinion Index',
                    style: {
                        fontFamily: 'Lato, sans-serif',
                        color: '#e76f51',
                    },
                },
                labels: {
                    format: '{value}',
                    style: {
                        fontFamily: 'Lato, sans-serif',
                        color: '#edf2f4',
                    },
                },
                plotLines: [
                    {
                        color: '#edf2f4',
                        width: 1,
                        value: 0,
                        zIndex: 3,
                    },
                ],
                plotBands: [
                    {
                        color: 'rgba(255, 107, 107, 0.3)',
                        from: 0,
                        to: -200,
                    },
                ],
            },

            tooltip: {
                shared: false,
                animation: true,
                backgroundColor: 'transparent',
                borderWidth: 0,
                shadow: false,
                style: {
                    color: '#edf2f4',
                    padding: 0,
                },
                useHTML: true,
                formatter: function () {
                    return (
                        '<div class="tooltip">' +
                        '<div class="tooltip-title">' +
                        this.point.name +
                        '</div>' +
                        '<div class="tooltip-content"> ' +
                        '</div>' +
                        '</div>'
                    );
                },
            },

            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 500,
                        },
                        chartOptions: {},
                    },
                ],
            },
        };
    }
    loadMap(data:any){
        const data1  = []
        const data2  = []
        const data3  = []
        for (let i = 0; i < 10; i++) {
            const obj = {
                x : data[i]['Sheet description'],
                y : data[i]['frequency_mention']
            }
            data1.push(obj)
        }
        for (let i = 11; i < 20; i++) {
            const obj = {
                x : data[i]['Sheet description'],
                y : data[i]['frequency_mention']
            }
            data2.push(obj)
        }
        for (let i = 21; i < 30; i++) {
            const obj = {
                x : data[i]['Sheet description'],
                y : data[i]['frequency_mention']
            }
            data3.push(obj)
        }

        if (data1.length > 0 && data2.length > 0 && data3.length > 0){ 
            this.topicOptions = {
                series: [
                    {
                        name: this.listname[0],
                        data: data1
                    },
                    {
                        name: this.listname[1],
                        data: data2
                    },
                    {
                        name: this.listname[2],
                        data: data3
                    },
                ],
                colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#9b5de5', '#ff6392'],
                plotOptions: {
                    treemap: {
                        distributed: true,
                        enableShades: false,
                    },
                },
                fill: {
                    opacity: 1,
                    type: 'solid',
                },
                legend: {
                    show: true,
                    fontSize: '14px',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                    offsetX: 0,
                    offsetY: 0,
                    formatter: function (seriesName) {
                        return seriesName.split('.').slice(0, -1).join('.');
                    },
                    labels: {
                        colors: '#edf2f4',
                        useSeriesColors: false,
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 10,
                    },
                },
                responsive: [
                    {
                        breakpoint: 800,
                        options: {
                            chart: {
                                height: 250,
                            },
                        },
                    },
                    {
                        breakpoint: 1025,
                        options: {
                            chart: {
                                height: 350,
                            },
                        },
                    },
                    {
                        breakpoint: 1441,
                        options: {
                            chart: {
                                height: 350,
                            },
                        },
                    },
                    {
                        breakpoint: 2561,
                        options: {
                            chart: {
                                height: 400,
                            },
                        },
                    },
                    {
                        breakpoint: 2561,
                        options: {
                            chart: {
                                height: 700,
                            },
                        },
                    },
                ],
                chart: {
                    height: 350,
                    type: 'treemap',
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            customIcons: [],
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    formatter: function (val, opts) {
                        return val;
                    },
                    textAnchor: 'middle',
                    distributed: false,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Lato, sans-serif',
                        fontWeight: 'bold',
                        colors: ['#36414c'],
                    },
                },
            };
        }
    }
}
