import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

enum ButtonType {
  Info = 'info',
  Danger = 'danger',
  Warning = 'warning',
  Success = 'success',
  LineInfo = 'line-info',
  LineDanger = 'line-danger',
  LineWarning = 'line-warning',
  LineSuccess = 'line-success',
}

@Component({
  selector: 'app-button[nz-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() class: string;
  @Input() style: string;

  @Input() type: string;
  @Input() size: string = 'default';
  @Input() icon: string;

  @Input() text: string;
  @Input() full: boolean = false
  @Input() disabled: boolean = false

  @Input() loading = false;
  @Output() click = new EventEmitter();

  get ButtonType() {
    switch (this.class) {
      case ButtonType.Info:
        return 'info';
      case ButtonType.Warning:
        return 'warning';
      case ButtonType.Danger:
        return 'danger';
      case ButtonType.Success:
        return 'success';
      case ButtonType.LineInfo:
        return 'line-info';
      case ButtonType.LineDanger:
        return 'line-danger';
      case ButtonType.LineWarning:
        return 'line-warning';
      case ButtonType.LineSuccess:
        return 'line-success';
    }
  }
  constructor() { }

  ngOnInit() {
  }

}
