import { Component,Input, Output, EventEmitter, OnInit } from '@angular/core';
enum TagStyle{
  Info = 'tag-info',
  Danger = 'tag-danger',
  Warning = 'tag-warning',
  Success = 'tag-success',
}
@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  @Input() text: string;
  @Input() icon: string;

  @Input() class: string;
  @Input() mode: string;

  @Output() close = new EventEmitter();

  get color() {
    switch (this.class) {
      case TagStyle.Info:
        return 'tag-info';
      case TagStyle.Danger:
        return 'tag-danger';
      case TagStyle.Warning:
        return 'tag-warning';
      case TagStyle.Success:
        return 'tag-success';
    }
  }
  constructor() { }

  ngOnInit() {
  }

}
