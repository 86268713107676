export const environment = {
  production: false,
  user: [
  {
    id:'127',
    clientname:'localhost',
    tokensocmed:'gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv',
    tokenformed:'098d7f66418bdd85055da08f5efe9b1e0b892176',
    urlsocmed:'https://digivla-sna.trinix.id/api/',
    urlformed: 'https://api.digivla.id/api/v1/',
    formedall:4189,
    formedcetak:4387,
    formedonline:4386,
    formedtv:4388,
    formedradio:4214,
    socmedtopicall:'124b33622936412fbb63fe15fa1a7bff',
    brandfeedbackmap : ["KKB", "KTA", "Giro", "UMKM"],
    topicuuidcompetitor:["0c1f6e8d52c84b00a68d34d0b142c2f9" , "16fe8b6a565745098ee8b4bd3f885112","0c1f6e8d52c84b00a68d34d0b142c2f9" , "16fe8b6a565745098ee8b4bd3f885112"]
  },
  {
    id:'bankdki',
    clientname:'bankdki',
    tokensocmed:'gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv',
    tokenformed:'098d7f66418bdd85055da08f5efe9b1e0b892176',
    urlsocmed:'https://digivla-sna.trinix.id/api/',
    urlformed: 'https://api.digivla.id/api/v1/',
    formedall:4189,
    formedcetak:4387,
    formedonline:4386,
    formedtv:4388,
    formedradio:4214,
    socmedtopicall:'124b33622936412fbb63fe15fa1a7bff',
    brandfeedbackmap : ["KKB", "KTA", "Giro", "UMKM"],
    topicuuidcompetitor:["0c1f6e8d52c84b00a68d34d0b142c2f9" , "16fe8b6a565745098ee8b4bd3f885112","0c1f6e8d52c84b00a68d34d0b142c2f9" , "16fe8b6a565745098ee8b4bd3f885112"]
  },
]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
