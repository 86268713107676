<span class="button-component">
  <button
    nz-button
    class="{{ ButtonType }}"
    nzType="{{ type }}"
    nzSize="{{ size }}"
    nzLoading="{{ loading }}"
    click="onClick($event)"
    disabled="{{ disabled }}"
    nzBlock="{{ full }}"
  >
    <span class="button-text">
      <icon name="{{ icon }}" theme="outline"></icon>
      {{ text }}
    </span>
  </button>
</span>
