import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgApexchartsModule } from "ng-apexcharts";
import { AntdModule } from '@shared/antd.module';

import { CardComponent } from "@components/card/card.component";
import { ModalComponent } from '@components/modal/modal.component';

import { ButtonComponent } from '@components/button/button.component';
import { LoadingComponent } from "@components/loading/loading.component";

import { IconComponent } from "@components/icon/icon.component";
import { TagComponent } from "@components/tag/tag.component";

import { BrandSentimentComponent } from "@shared/charts/brandSentiment/brandSentiment.component";

@NgModule({
  imports: [CommonModule, AntdModule, NgApexchartsModule],
  exports: [
    AntdModule,
    CardComponent,
    LoadingComponent,
    IconComponent,
    TagComponent,
    ModalComponent,
    ButtonComponent,
    BrandSentimentComponent,
  ],
  declarations: [
    CardComponent,
    LoadingComponent,
    IconComponent,
    TagComponent,
    ModalComponent,
    ButtonComponent,
    BrandSentimentComponent,
  ],
})
export class SharedModule {}
