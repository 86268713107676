import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HighchartsChartModule } from 'highcharts-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SharedModule } from '@shared/shared.module';

import { LayoutComponent } from '@core/layout/layout.component';

import { CustomerComponent } from '@pages/customer/customer.component';
import { EmployeeComponent } from '@app/pages/employee/employee.component';
import { BrandingComponent } from '@pages/branding/branding.component';
import { MediaComponent } from '@pages/media/media.component';
import { CompetitorComponent } from '@pages/competitor/competitor.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    SharedModule,
    HighchartsChartModule,
    NgApexchartsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LayoutComponent,
    CustomerComponent,
    EmployeeComponent,
    BrandingComponent,
    MediaComponent,
    CompetitorComponent
  ],
  exports:[FormsModule]
})
export class LayoutModule {}
