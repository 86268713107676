<div
  nz-row
  [nzGutter]="[
    { xs: 8, sm: 10, md: 12, lg: 12, xl: 16 },
    { xs: 8, sm: 10, md: 12, lg: 12, xl: 16 }
  ]"
>
  <div nz-col nzXs="24" nzMd="24" nzLg="24" nzXl="24" *ngIf="!trigershowchart">
    <card nz-card title="Submit The Document" [color]="baseColor">
      <h5 style="color: white; margin: 0 0 1rem">*You must upload {{ filecount }} document</h5>
      <input type="file" (change)="onFileChange($event)" style="color: white" [formControl]="downloadFc" />
    </card>
  </div>
  <div nz-col nzXs="24" nzMd="24" nzLg="15" nzXl="18" *ngIf="trigershowchart">
    <card nz-card title="employee feedback map" [color]="baseColor">
      <div class="chart-component">
        <highcharts-chart class="feedback" [Highcharts]="Highcharts" [options]="feedbackOptions"></highcharts-chart>
      </div>
    </card>
  </div>
  <div nz-col nzXs="24" nzMd="24" nzLg="9" nzXl="6" *ngIf="trigershowchart">
    <card nz-card title="employee delight point" [color]="baseColor">
      <div class="list-wrap">
        <div class="list-item" *ngFor="let item of dataDelight[0]">
          <div class="list-head">
            <div class="list-label">
              <icon class="label-icon" name="file-protect" theme="outline" size="15px" color="#edf2f4"></icon>
              <a>{{ item["Sheet description"] }}</a>
            </div>
            <div class="list-icon">
              <icon name="fund" theme="fill" size="15px" color="#ffe66d"></icon>
              {{ item["opinion_index"] }}
            </div>
          </div>
          <div class="list-content">
            <span nz-tooltip nzTooltipPlacement="left" [nzTooltipTitle]="item['example_mention']">
              {{ item["example_mention"] | slice: 0:100 }}
            </span>
          </div>
          <div class="list-info">
            <app-tag
              *ngIf="item['opinion_index'] > 0"
              class="tag-success"
              icon="plus-circle"
              [text]="item['opinion_index']"
            ></app-tag>
            <app-tag
              *ngIf="item['opinion_index'] < 0"
              class="tag-danger"
              icon="minus-circle"
              [text]="item['opinion_index']"
            ></app-tag>
          </div>
        </div>
      </div>
    </card>
  </div>
  <div nz-col nzXs="24" nzMd="24" nzLg="15" nzXl="18" *ngIf="trigershowchart">
    <card nz-card title="employee feedback topic map" [color]="baseColor">
      <div class="chart-component">
        <apx-chart
          class="opinion"
          [series]="topicOptions.series"
          [chart]="topicOptions.chart"
          [colors]="topicOptions.colors"
          [fill]="topicOptions.fill"
          [grid]="topicOptions.grid"
          [dataLabels]="topicOptions.dataLabels"
          [plotOptions]="topicOptions.plotOptions"
          [legend]="topicOptions.legend"
        ></apx-chart>
      </div>
    </card>
  </div>
  <div nz-col nzXs="24" nzMd="24" nzLg="9" nzXl="6" *ngIf="trigershowchart">
    <card nz-card title="employee pain point" [color]="baseColor">
      <div class="list-wrap">
        <div class="list-item" *ngFor="let item of dataDelight2[0]">
          <div class="list-head">
            <div class="list-label">
              <icon class="label-icon" name="file-protect" theme="outline" size="15px" color="#edf2f4"></icon>
              <a>{{ item["Sheet description"] }}</a>
            </div>
            <div class="list-icon">
              <icon name="fund" theme="fill" size="15px" color="#ffe66d"></icon>
              {{ item["opinion_index"] }}
            </div>
          </div>
          <div class="list-content">
            <span nz-tooltip nzTooltipPlacement="left" [nzTooltipTitle]="item['example_mention']">
              {{ item["example_mention"] | slice: 0:100 }}
            </span>
          </div>
          <div class="list-info">
            <app-tag
              *ngIf="item['opinion_index'] > 0"
              class="tag-success"
              icon="plus-circle"
              [text]="item['opinion_index']"
            ></app-tag>
            <app-tag
              *ngIf="item['opinion_index'] < 0"
              class="tag-danger"
              icon="minus-circle"
              [text]="item['opinion_index']"
            ></app-tag>
          </div>
        </div>
      </div>
    </card>
  </div>
</div>
