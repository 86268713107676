import { Component, OnInit } from '@angular/core';
import { HeaderService } from '@services/header.service';
import * as Highcharts from 'highcharts';

import { mediaList } from '@core/mock/listData';
import { ContentService } from "@services/content.service";
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../../environments/environment";
import { Subject, Subscription } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { takeUntil } from "rxjs/operators";
@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit {
  dataMedia = mediaList;
  Highcharts = Highcharts;
  volumeOptions = {};
  sentimentOptions = {};
  medialisttvget = []
  medialistarticleget = []
  preview: string;
  filemediatv: any;
  filemediaradio: any;
  filemediacetak: any;
  filemediaonline: any;
  show: boolean = false;
  showmediapickupvolume: boolean = false;
  formalshow: boolean = false;
  sosialshow: boolean = false;
  sosialmodalList = [];
  formedmodalList = [];
  modalDetailData = [];
  modalVisible = false;
  modalDetail = false;
  date = {
    end_date: new Date(new Date()).toISOString().slice(0, 10),
    start_date: new Date(new Date(new Date().setDate(new Date().getDate() - 7))).toISOString().slice(0, 10)
  }
  subscription: Subscription;
  subject = webSocket("wss://ifap.digivla.com:8091");
  // subject = webSocket('wss://echo.websocket.org')
  destroy$: Subject<boolean> = new Subject<boolean>();
  mediaRawData: any;
  constructor(private headerService: HeaderService,
    private contentSvc: ContentService,
    private sanitizer: DomSanitizer) { }
  showModalList() {
    this.modalVisible = true;
  }

  showModalDetail(item) {
    this.modalDetailData = [
      {
        title: item.headline,
        summary: item.summary,
        media: item.media,
        date: item.date
      }
    ];
    this.modalDetail = true;
    this.modalVisible = false;
  }

  handleCancel(): void {
    this.modalVisible = false;
    this.modalDetail = false;
  }

  handleBack(): void {
    this.modalDetail = false;
    this.modalVisible = true;
  }

  ngOnInit() {
    this.headerService.setTitle('media perception');
    this.headerService.setColor('#06d6a0');
    let end_date = new Date(new Date()).toISOString().slice(0, 10);
    let start_date =  new Date(new Date(new Date().setDate(new Date().getDate() - 7))).toISOString().slice(0, 10)
    this.loadmedianewsArticle(start_date, end_date)
    this.loadmediaSentiment();
    this.loadmediapickupvolume();
    this.subject.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.date['end_date'] = data['end']
      this.date['start_date'] = data['start']
      const start_date = data['start']
      const end_date = data['end']
      this.loadmedianewsArticle(start_date, end_date)
      this.loadmediaSentiment();
      this.loadmediapickupvolume();
    });
  }

  loadmediapickupvolume() {
    let dataGraph = [];
    let xAxisGraph = [];
    let pieChartDataArr = [];
    let dataSubCategory = [];
    this.contentSvc.postmediapickupVolume(this.date).subscribe(
      result => {
        if (result.data.length > 0) {
          dataGraph = [];
          xAxisGraph = [];
          pieChartDataArr = [];
          for (let i = 0; i < result.data.length; i++) {
            let dataSubCategory = [];
            for (
              let j = 0;
              j < result.data[i].category_id_per_day.buckets.length;
              j++
            ) {
              dataSubCategory.push(
                result.data[i].category_id_per_day.buckets[j].doc_count
              );
            }
            dataGraph.push({
              name: result.data[i].key,
              data: dataSubCategory,
              type: "spline"
            });
            pieChartDataArr.push({
              name: result.data[i].key,
              y: result.data[i].doc_count
            });
          }
          for (
            let i = 0;
            i < result.data[0].category_id_per_day.buckets.length;
            i++
          ) {
            xAxisGraph.push(
              result.data[0].category_id_per_day.buckets[i].key_as_string
            );
          }
          this.volumeOptions = {
            colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#e76f51'],
            series: dataGraph,
            chart: {
              type: 'line',
              backgroundColor: null,
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#edf2f4',
              },
            },

            title: {
              text: null,
            },

            credits: {
              enabled: false,
            },

            exporting: {
              enabled: false,
            },

            plotOptions: {
              series: {
                dataLabels: {
                  enabled: false,
                },
                point: {
                  events: {
                    click: (e) => {
                      this.loadpickupvolume(e.point.category, e.point.series.name)
                    }
                  }
                },
                marker: {
                  symbol: 'circle',
                  lineColor: null,
                  lineWidth: 2,
                },
              },
            },

            yAxis: {
              gridLineWidth: 0,
              gridLineDashStyle: 'longdash',
              labels: {
                format: ' {value}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
              title: {
                text: null,
              },
            },

            xAxis: {
              gridLineWidth: 1,
              gridLineDashStyle: 'longdash',
              categories: xAxisGraph,
              title: {
                text: null,
              },
              labels: {
                align: 'center',
                format: '{value:%b}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
            },

            // tooltip: {
            //   shared: true,
            //   animation: true,
            //   backgroundColor: 'transparent',
            //   borderWidth: 0,
            //   shadow: false,
            //   style: {
            //     color: '#edf2f4',
            //     fontFamily: 'Lato, sans-serif',
            //   },
            //   useHTML: true,
            //   headerFormat:
            //     '<div class="tooltip share"><div class="tooltip-title"> {point.key:%b} </div>',
            //   pointFormat:
            //     '<div class="tooltip-content share"><div class="series-name""><span class="dot" style="background-color: {series.color};"></span>{series.name}:<span class="series-value">{point.y}</span></div></div>',
            //   footerFormat: '</div>',
            // },

            legend: {
              enabled: true,
              itemStyle: {
                color: '#edf2f4',
                fontFamily: 'Lato, sans-serif',
              },
              itemHoverStyle: {
                color: '#495867',
              },
            },
          };
          this.showmediapickupvolume = true;
        }
      }
    )
  }
  loadpickupvolume(date, data) {
    this.formedmodalList = [];
    this.formalshow = true
    this.sosialshow = false
    this.modalVisible = true
    const datause = {
      category_id: data,
      end_date: date,
      start_date: date,
    }
    this.contentSvc.postcontentEws(datause).subscribe(
      result => {
        result.data.forEach(element => {
          const dataobj = {
            headline: element.title,
            summary: element.content,
            media: element.media_name,
            date: element.datee
          }
          this.formedmodalList.push(dataobj)
        });
      }
    )

  }
  loadmediaSentiment() {
    let datacategory = []
    let positive = []
    let neutral = []
    let negative = []
    this.contentSvc.postmediaTone(this.date).subscribe(
      result => {
        if (result) {
          this.mediaRawData = result;
          result.data.forEach(element => {
            datacategory.push(element.media_name)
          });
          for (let i = 0; i < result.data.length; i++) {
            for (let j = 0; j < result.data[i].tones.length; j++) {
              if (result.data[i].tones[j].positive != undefined) {
                positive.push(result.data[i].tones[j].positive);
              } else if (result.data[i].tones[j].neutral != undefined) {
                neutral.push(result.data[i].tones[j].neutral);
              } else {
                negative.push(result.data[i].tones[j].negative);
              }
            }
          }
          this.sentimentOptions = {
            colors: ['#06d6a0', '#00bbf9', '#ff6b6b'],
            series: [
              {
                name: 'positive',
                data: positive
              },
              {
                name: 'neutral',
                data: neutral,
              },
              {
                name: 'negative',
                data: negative,
              },
            ],
            chart: {
              type: 'bar',
              backgroundColor: 'transparent',
              style: {
                fontFamily: 'Lato, sans-serif',
              },
            },
            title: undefined,
            credits: undefined,
            exporting: undefined,
            legend: {
              enabled: true,
              reversed: true,
              itemStyle: {
                color: '#edf2f4',
                fontFamily: 'Lato, sans-serif',
              },
              itemHoverStyle: {
                color: '#495867',
              },
            },
            plotOptions: {
              series: {
                stacking: 'normal',
                dataLabels: {
                  enabled: true
                },
                allowPointSelect: true,
                point: {
                  events: {
                    select: this.onMediaSelection.bind(this)
                  }
                }
              },
            },
            xAxis: {
              title: undefined,
              gridLineWidth: 0.3,
              type: 'category',
              labels: {
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
              categories: datacategory
            },
            yAxis: {
              title: undefined,
              gridLineWidth: 1,
              gridLineDashStyle: 'longdash',
              labels: {
                enabled: true,
                align: 'center',
                rotation: 0,
                format: '{value}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
            },
            tooltip: {
              shared: false,
              animation: true,
              backgroundColor: 'transparent',
              borderWidth: 0,
              shadow: false,
              style: {
                color: '#edf2f4',
                fontFamily: 'Lato, sans-serif',
              },
              useHTML: true,
              headerFormat:
                '<div class="tooltip share"><div class="tooltip-title"> {point.key:%b} </div>',
              pointFormat:
                '<div class="tooltip-content share"><div class="series-name""><span class="dot" style="background-color: {series.color};"></span>{series.name}:<span class="series-value">{point.y}</span></div></div>',
              footerFormat: '</div>',
            },
          };
          this.show = true
        }
      }
    )
  }
  onMediaSelection(res) {
    let media_id;
    this.formedmodalList = [];
    this.formalshow = true
    this.sosialshow = false
    this.modalVisible = true
    for (let i = 0; i < this.mediaRawData.data.length; i++) {
      if (this.mediaRawData.data[i].media_name == res.target.category) {
        media_id = this.mediaRawData.data[i].media_id;
      }
    }
    const toneStr =
      res.target.series.name == "positive"
        ? 1
        : res.target.series.name == "neutral"
          ? 0
          : -1;
    this.date['tone'] = toneStr
    this.date['media_id'] = media_id
    this.contentSvc.postcontentBymedia(this.date).subscribe(
      result => {
        result.data.forEach(element => {
          const dataobj = {
            headline: element.title,
            summary: element.content,
            media: element.media_name,
            date: element.datee
          }
          this.formedmodalList.push(dataobj)
        });
      }
    )
  }
  loadmedianewsArticle(start_date: any, end_date: any) {
    const paramscetak = {
      end_date: end_date,
      start_date: start_date,
      user_media_type_id: this.contentSvc.formedCetak,
      maxSize: 10
    }
    const paramsonline = {
      end_date: end_date,
      start_date: start_date,
      user_media_type_id: this.contentSvc.formedOnline,
      maxSize: 10
    }
    const paramstv = {
      end_date: end_date,
      start_date: start_date,
      user_media_type_id: this.contentSvc.formedTv,
      maxSize: 10
    }
    const paramsradio = {
      end_date: end_date,
      start_date: start_date,
      user_media_type_id: this.contentSvc.formedRadio,
      maxSize: 10
    }
    this.medialisttvget = []
    this.medialistarticleget = []
    this.contentSvc.postmedianewsArticle(paramstv).subscribe(
      result => {
        result.data.forEach(element => {
          this.medialisttvget.push(element)
        });
        this.contentSvc.postmedianewsArticle(paramsradio).subscribe(
          result => {
            result.data.forEach(element => {
              // this.medialisttvget.push(element)
            });
            this.contentSvc.postmedianewsArticle(paramscetak).subscribe(
              result => {
                result.data.forEach(element => {
                  this.medialistarticleget.push(element)
                });
                this.contentSvc.postmedianewsArticle(paramsonline).subscribe(
                  result => {
                    result.data.forEach(element => {
                      this.medialistarticleget.push(element)
                    });
                    if (this.medialisttvget.length > 0) {
                      this.preview = this.medialisttvget[0].media_type
                      if (this.preview === 'media tv') {
                        const filesanttv = 'https://input.digivla.id/media_tv/' + this.medialisttvget[0].file_pdf.substr(0, 10).replace(/\-/g, '/') + '/' + this.medialisttvget[0].file_pdf
                        this.filemediatv = filesanttv
                      } else {
                        const filesanradio = 'http://input.digivla.id/media/radio_files/' + this.medialisttvget[0].file_pdf.substr(0, 10).replace(/\-/g, '/') + '/' + this.medialisttvget[0].file_pdf
                        this.filemediaradio = filesanradio
                      }
                    } else {
                      this.preview = this.medialistarticleget[0].media_type
                      if (this.preview === 'media cetak') {
                        const filesancetak = 'http://pdf.antara-insight.id/pdf_images/' + this.medialistarticleget[0].file_pdf.substr(0, 10).replace(/\-/g, '/') + '/' + this.medialistarticleget[0].file_pdf
                        const embed = 'https://drive.google.com/viewerng/viewer?embedded=true&url=' + filesancetak + '#toolbar=0&scrollbar=0'
                        this.filemediacetak = this.sanitizer.bypassSecurityTrustResourceUrl(embed)
                      } else {
                        this.filemediaonline = this.medialistarticleget[0].content
                      }
                    }
                  }

                )

              }
            )


          }
        )
      }
    )



  }
  clicktype(type: any) {
    this.preview = type.media_type
    switch (type.media_type) {
      case 'media tv':
        const filesanttv = 'https://input.digivla.id/media_tv/' + type.file_pdf.substr(0, 10).replace(/\-/g, '/') + '/' + type.file_pdf
        this.filemediatv = filesanttv
        break;
      case 'media online':
        this.filemediaonline = type.content
        break;
      case 'media radio':
        const filesanradio = 'http://input.digivla.id/media/radio_files/' + type.file_pdf.substr(0, 10).replace(/\-/g, '/') + '/' + type.file_pdf
        this.filemediaradio = filesanradio
        break;
      case 'media cetak':
        const filesancetak = 'http://pdf.antara-insight.id/pdf_images/' + type.file_pdf.substr(0, 10).replace(/\-/g, '/') + '/' + type.file_pdf
        const embed = 'https://drive.google.com/viewerng/viewer?embedded=true&url=' + filesancetak + '#toolbar=0&scrollbar=0'
        this.filemediacetak = this.sanitizer.bypassSecurityTrustResourceUrl(embed)
        break;
      default:
        break;
    }
  }
}
