<div
  nz-row
  [nzGutter]="[
    { xs: 8, sm: 10, md: 12, lg: 12, xl: 12, xxl: 16 },
    { xs: 8, sm: 10, md: 12, lg: 12, xl: 12, xxl: 16 }
  ]"
>
  <div nz-col [nzSpan]="24" >
    <card nz-card title="brand association" [color]="baseColor">
      <div
        nz-row
        [nzGutter]="[
          { xs: 8, sm: 10, md: 12, lg: 12, xl: 12, xxl: 16 },
          { xs: 8, sm: 10, md: 12, lg: 12, xl: 12, xxl: 16 }
        ]"
      >
        <div nz-col [nzXs]="24" [nzMd]="24" [nzLg]="8" [nzXl]="8" *ngFor="let item of dataalltopicUuid">
          <h5 style="color: white;">{{item.chart.series[0].name}}</h5>
          <div class="chart-component">
            <apx-chart
              class="tree-item"
              [series]="item.chart.series"
              [labels]="item.chart.labels"
              [colors]="item.chart.colors"
              [chart]="item.chart.chart"
              [plotOptions]="item.chart.plotOptions"
              [dataLabels]="item.chart.dataLabels"
              [stroke]="item.chart.stroke"
              [tooltip]="item.chart.tooltip"
              [legend]="item.chart.legend"
            ></apx-chart>
          </div>
        </div>
      </div>
    </card>
  </div>
  <div nz-col [nzSpan]="24">
    <div
      nz-row
      [nzGutter]="[
        { xs: 8, sm: 10, md: 12, lg: 12, xl: 12, xxl: 16 },
        { xs: 8, sm: 10, md: 12, lg: 12, xl: 12, xxl: 16 }
      ]"
    >
      <div nz-col nzXs="24" nzMd="24" nzLg="8" nzXl="6">
        <card nz-card title="competitor ad value" [color]="baseColor">
          <div class="chart-component">
            <apx-chart
              *ngIf="pieAdValue?.series"
              class="pie-value"
              [series]="pieAdValue?.series"
              [labels]="pieAdValue?.labels"
              [colors]="pieAdValue?.colors"
              [chart]="pieAdValue?.chart"
              [plotOptions]="pieAdValue?.plotOptions"
              [stroke]="pieAdValue?.stroke"
              [tooltip]="pieAdValue?.tooltip"
              [legend]="pieAdValue?.legend"
            ></apx-chart>
          </div>
        </card>
      </div>
      <div nz-col nzXs="24" nzMd="24" nzLg="16" nzXl="18">
        <card nz-card class="brand-card" title="competitor sentiment" [color]="baseColor">
          <div nz-row *ngIf="brandcomp1">
            <div nz-col nzXs="12" nzMd="12" nzLg="6" nzXl="6" *ngFor="let pie of brandComp1">
              <apx-chart
                [series]="pie.series"
                [labels]="pie.label"
                [colors]="pieChart.colors"
                [chart]="pie.chart"
                [plotOptions]="pie.plotOptions"
                [dataLabels]="pie.dataLabels"
                [stroke]="pieChart.stroke"
                [tooltip]="pieChart.tooltip"
                [legend]="pieChart.legend"
                [responsive]="pie.responsive"
              ></apx-chart>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>

<modal nz-modal width="70%" title="article list" [visible]="modalVisible" closeable="false">
  <div content>
    <nz-tabset nzSize="small" nzTabPosition="top">
      <nz-tab nzTitle="Formal Media" *ngIf="formalshow">
        <div class="list-wrap-modal">
          <div class="list-item" *ngFor="let item of formedmodalList">
            <div class="list-head">
              <div class="list-label">
                <a (click)="showModalDetail(item)">{{ item.headline }}</a>
              </div>
            </div>
            <div class="list-content">
              {{ item.summary }}
            </div>
            <div class="list-info">
              <div class="info-item"><icon name="notification"></icon>{{ item.media }}</div>
              <div class="info-item"><icon name="calendar"></icon>{{ item.date }}</div>
            </div>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Social Media" *ngIf="sosialshow">
        <div class="list-wrap-modal">
          <div class="list-item" *ngFor="let item of sosialmodalList">
            <div class="list-head">
              <div class="list-label">
                <a (click)="showModalDetail(item)">{{ item.headline }}</a>
              </div>
            </div>
            <div class="list-content">
              {{ item.summary }}
            </div>
            <div class="list-info">
              <div class="info-item"><icon name="notification"></icon>{{ item.media }}</div>
              <div class="info-item"><icon name="calendar"></icon>{{ item.date }}</div>
            </div>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
  <div footer>
    <app-button
      nz-button
      type="dashed"
      class="line-danger"
      icon="close-circle"
      text="cancel"
      (click)="handleCancel()"
    ></app-button>
  </div>
</modal>

<modal nz-modal width="70%" title="article detail" [visible]="modalDetail" closeable="false">
  <div content *ngFor="let item of modalDetailData">
    <div class="modal-body-content">
      <div class="section-label">
        <div class="modal-section label-col">
          <div class="modal-item">
            <div class="item-title">title</div>
            <span>{{ item.title }}</span>
          </div>
          <div class="modal-item">
            <div class="item-title">date</div>
            <span>{{ item.date }}</span>
          </div>
        </div>
        <div class="modal-section label-col">
          <div class="modal-item">
            <div class="item-title">media</div>
            <span>{{ item.media }}</span>
          </div>
        </div>
      </div>
      <div class="section-text">
        {{ item.summary }}
      </div>
    </div>
  </div>
  <div footer>
    <app-button
      nz-button
      type="dashed"
      class="line-info"
      icon="left-circle"
      text="back"
      (click)="handleBack()"
    ></app-button>
    <app-button
      nz-button
      type="dashed"
      class="line-danger"
      icon="close-circle"
      text="cancel"
      (click)="handleCancel()"
    ></app-button>
  </div>
</modal>
