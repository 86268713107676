import { Injectable } from '@angular/core';
import { Observable, Subject} from "rxjs";
import { SocketService } from "./socket.service";
import { map } from 'rxjs/operators';
// const CHAT_URL = "ws://echo.websocket.org/";
const CHAT_URL = "wss://echo.websocket.org/";

export interface Message {
  period: string;

}
@Injectable({
  providedIn: 'root'
})
export class ConfigsocketService {
  public messages: Subject<Message>;

  constructor(wsService: SocketService) {
    this.messages = <Subject<Message>>wsService.connect(CHAT_URL).pipe(map((response: MessageEvent): Message => {
        let data = JSON.parse(response.data);
        return {period: data.period}
      })
    );
  }
}
