import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from '@services/header.service';
import { ContentService } from "@services/content.service";

import { pieOptions } from '@data/pieChart';
import { ChartComponent } from 'ng-apexcharts';

import { money, flazz, tapcash, jenius } from '@core/mock/treemap';

import * as Highcharts from 'highcharts';
declare var require: any;
const Wordcloud = require('highcharts/modules/wordcloud');
Wordcloud(Highcharts);

import { Subject, Subscription } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { takeUntil } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { SubscriptionLoggable } from 'rxjs/internal/testing/SubscriptionLoggable';
import { ɵKeyEventsPlugin } from '@angular/platform-browser';
@Component({
  selector: 'app-competitor',
  templateUrl: './competitor.component.html',
  styleUrls: ['./competitor.component.scss']
})
export class CompetitorComponent implements OnInit {
  public pieChart = pieOptions;
  baseColor = "#ff6b6b";
  public tabsTitle = ['e-money', 'bca flazz', 'tapcash', 'jenius'];

  @ViewChild("chart") chart: ChartComponent;
  public pieSentiment: any;
  public pieAdValue: any
  public associate: any;

  preview: string;
  filemediatv: any;
  filemediaradio: any;
  filemediacetak: any;
  filemediaonline: any;

  Highcharts = Highcharts;
  mediaRawData: any;
  wordcloudOptions: any;

  sentimentOptions = {};
  showsentiment: boolean = false;
  date = {
    edate: new Date(new Date()).toISOString().slice(0, 10),
    sdate: new Date(new Date(new Date().setDate(new Date().getDate() - 7))).toISOString().slice(0, 10)
  }
  public brandComp1;
  brandcomp1:boolean = false;
  showwordcloud: boolean = false;
  subscription: Subscription;
  subject = webSocket("wss://ifap.digivla.com:8091");
  // subject = webSocket('wss://echo.websocket.org')
  destroy$: Subject<boolean> = new Subject<boolean>();
  datalengthslide = [];
  topicuuidCompetitor:any;
  dataalltopicUuid = []
  listhidetopic = []
  labeltopicselected = []
  updatehideLabel:boolean = false;
  modalDetailData = [];
  modalDetail = false;
  sosialmodalList = [];
  formedmodalList = [];
  listdata = [1,2,3]
  modalVisible = false;
  formalshow: boolean = false;
  sosialshow: boolean = false;
  public treeMapOptions: any;
  constructor(private headerService: HeaderService, private contentSvc: ContentService) {
    for (let i = 0; i < environment.user.length; i++) {
      if (window.location.hostname.split('.')[0] == environment.user[i].id) {
        this.topicuuidCompetitor = environment.user[i].topicuuidcompetitor
      }
    }
  }

  ngOnInit(): void {
    this.headerService.setTitle('competitor landscape');
    this.headerService.setColor(this.baseColor);
    this.loadPie();
    this.loadAdValue();
    this.loaddummyassociate()
    // this.loadAssociate();
    // this.loadcompetition();
    this.loaddummycompetitor()
    this.subject.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.date['edate'] = data['end'];
      this.date['sdate'] = data['start'];
      this.loadPie();
      this.loadAdValue();
      this.loaddummyassociate()
      // this.loadAssociate();
    });
  }

  handleCancel(): void {
    this.modalVisible = false;
    this.modalDetail = false;
  }

  handleBack(): void {
    this.modalDetail = false;
    this.modalVisible = true;
  }
  loadcompetition() {
    this.contentSvc.postbrandcompetition(this.date).subscribe(
      result => {
        const datatemp = []
        for (let i = 0; i < Math.ceil(result.length / 3); i++) {
          this.datalengthslide.push(i)
        }
        result.forEach(element => {
          const dataobj = {
            series: Object.values(element)[0],
            chart: {
              height: '100%',
              width: '100%',
              type: "donut",
              startRange: 0,
            },
            plotOptions: {
              pie: {
                expandOnClick: true,
                dataLabels: pieOptions.plotOptions.pie.dataLabels,
                donut: {
                  size: pieOptions.plotOptions.pie.donut.size,
                  background: pieOptions.plotOptions.pie.donut.background,
                  labels: {
                    show: true,
                    name: pieOptions.plotOptions.pie.donut.labels.name,
                    value: pieOptions.plotOptions.pie.donut.labels.value,
                    total: {
                      show: true,
                      showAlways: true,
                      label: Object.keys(element)[0],
                      fontSize: pieOptions.plotOptions.pie.donut.labels.total.fontSize,
                      fontFamily: pieOptions.plotOptions.pie.donut.labels.total.fontFamily,
                      fontWeight: pieOptions.plotOptions.pie.donut.labels.total.fontWeight,
                      color: pieOptions.plotOptions.pie.donut.labels.total.color,
                    },
                  },
                },
              },
            },
            dataLabels: pieOptions.dataLabels,
            responsive: [
              {
                breakpoint: 900,
                options: {
                  chart: {
                    width: '100%',
                    height: 100,
                  },
                },
              },
              {
                breakpoint: 1025,
                options: {
                  chart: {
                    width: '100%',
                    height: 200,
                  },
                },
              },
              {
                breakpoint: 1440,
                options: {
                  chart: {
                    width: '100%',
                    height: 250,
                  },
                },
              },
              {
                breakpoint: 1921,
                options: {
                  chart: {
                    width: '100%',
                    height: 250,
                  },
                },
              },
              {
                breakpoint: 2561,
                options: {
                  chart: {
                    width: '100%',
                    height: 400,
                  },
                },
              },
            ],
          }
          datatemp.push(dataobj)
        });
        this.brandComp1 = datatemp    
        this.brandcomp1 = true
      }
    )
  }

  loaddummycompetitor(){
    this.contentSvc.postdummycompetitorsentiment().subscribe(
      result => {
        if (result){
          const datatemp = []
          result.forEach(element => {
            const series = []
            Object.keys(element.sentiment).forEach((key) =>{
              series.push(element.sentiment[key])
            });
            const dataobj = {
              series: series,
              chart: {
                height: '100%',
                width: '100%',
                type: "donut",
                startRange: 0,
              },
              plotOptions: {
                pie: {
                  expandOnClick: true,
                  dataLabels: pieOptions.plotOptions.pie.dataLabels,
                  donut: {
                    size: pieOptions.plotOptions.pie.donut.size,
                    background: pieOptions.plotOptions.pie.donut.background,
                    labels: {
                      show: true,
                      name: pieOptions.plotOptions.pie.donut.labels.name,
                      value: pieOptions.plotOptions.pie.donut.labels.value,
                      total: {
                        show: true,
                        showAlways: true,
                        label: element.brand,
                        fontSize: pieOptions.plotOptions.pie.donut.labels.total.fontSize,
                        fontFamily: pieOptions.plotOptions.pie.donut.labels.total.fontFamily,
                        fontWeight: pieOptions.plotOptions.pie.donut.labels.total.fontWeight,
                        color: pieOptions.plotOptions.pie.donut.labels.total.color,
                      },
                    },
                  },
                },
              },
              dataLabels: pieOptions.dataLabels,
              responsive: [
                {
                  breakpoint: 900,
                  options: {
                    chart: {
                      width: '100%',
                      height: 100,
                    },
                  },
                },
                {
                  breakpoint: 1025,
                  options: {
                    chart: {
                      width: '100%',
                      height: 200,
                    },
                  },
                },
                {
                  breakpoint: 1440,
                  options: {
                    chart: {
                      width: '100%',
                      height: 250,
                    },
                  },
                },
                {
                  breakpoint: 1921,
                  options: {
                    chart: {
                      width: '100%',
                      height: 250,
                    },
                  },
                },
                {
                  breakpoint: 2561,
                  options: {
                    chart: {
                      width: '100%',
                      height: 400,
                    },
                  },
                },
              ],
            }
            datatemp.push(dataobj)
          });
          this.brandComp1 = datatemp    
          this.brandcomp1 = true
        }
      }
    )
  }



  loadAssociate() {
    this.topicuuidCompetitor.forEach((element , index) => {
        const payload = {
           "sdate": this.date.sdate,
          "edate": this.date.edate,
          "topic_uuid": element,
          "type": "word_cloud",
          "most_common": 15
        }
        this.contentSvc.postbrandCompetitor(payload).subscribe(
          result => {
              const labeltopic = []
              const obj = {
                id : result.topic,
              }
              Object.keys(result.hidden_topic).forEach((key) => {
                const objtopic ={
                  label: key,
                  value:key,
                  checked:result.hidden_topic[key]
                }
                labeltopic.push(objtopic)
                if (result.hidden_topic[key]){
                  this.labeltopicselected.push(key)
                  if (!this.listhidetopic.includes(key)) this.listhidetopic.push(key)
                }
              });
              obj['topic'] = labeltopic
              const seriesData = []
              result.wordcloud.forEach(element => {
                const objseries = {
                  x : element.key,
                  y:element.doc_count
                }
                seriesData.push(objseries)
                obj['chart'] = {
                  series: [{name:obj.id , data:seriesData}],
                  colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#9b5de5', '#ff6392'],
                  plotOptions: {
                    treemap: {
                      distributed: false,
                      enableShades: false,
                    },
                  },
                  fill: {
                    opacity: 1,
                    type: 'solid',
                  },
                  legend: {
                    show: false,
                    fontSize: '14px',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                      colors: '#edf2f4',
                      useSeriesColors: false,
                    },
                    itemMargin: {
                      horizontal: 5,
                      vertical: 10,
                    },
                  },
                  chart: {
                    height: '100%',
                    width: '100%',
                    type: 'treemap',
                    toolbar: {
                      show: true,
                      offsetX: 0,
                      offsetY: 0,
                      tools: {
                        download: false,
                        selection: false,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        customIcons: [],
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    formatter: function (val, opts) {
                      return val;
                    },
                    textAnchor: 'middle',
                    distributed: false,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      fontSize: '12px',
                      fontFamily: 'Lato, sans-serif',
                      fontWeight: 'bold',
                      colors: ['#36414c'],
                    },
                  },
                }
              });
              this.dataalltopicUuid.push(obj)
              if (this.dataalltopicUuid.length === this.topicuuidCompetitor.length) this.updatehideLabel = true
          }
        )        
    });
  }
  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  loaddummyassociate(){
    this.contentSvc.postdummybrandassociationcompetitor().subscribe(
      result => {
        if (result){
              Object.keys(result.data).forEach((key) => {
                let seriesData = []
                const obj = {}
                result.data[key].forEach(element => {
                  const objseries = {
                    x : element.key,
                    y:element.doc_count
                  }
                  seriesData.push(objseries)    
                });
                 obj['topic'] = key
                 obj['chart'] = {
                  series: [{name:key , data:seriesData}],
                  colors: [this.getRandomColor()],
                  plotOptions: {
                    treemap: {
                      distributed: false,
                      enableShades: false,
                    },
                  },
                  fill: {
                    opacity: 1,
                    type: 'solid',
                  },
                  legend: {
                    show: true,
                    fontSize: '14px',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                      colors: '#edf2f4',
                      useSeriesColors: false,
                    },
                    itemMargin: {
                      horizontal: 5,
                      vertical: 10,
                    },
                  },
                  chart: {
                    height: '100%',
                    width: '100%',
                    type: 'treemap',
                    toolbar: {
                      show: true,
                      offsetX: 0,
                      offsetY: 0,
                      tools: {
                        download: false,
                        selection: false,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        customIcons: [],
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    formatter: function (val, opts) {
                      return val;
                    },
                    textAnchor: 'middle',
                    distributed: true,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      fontSize: '14px',
                      fontFamily: 'Lato, sans-serif',
                      fontWeight: 'bold',
                      colors: ['#ffffff'],  
                    },
                  },
                  responsive: [
                    {
                      breakpoint: 800,
                      options: {
                        chart: {
                          height: 250,
                        },
                      },
                    },
                    {
                      breakpoint: 1025,
                      options: {
                        chart: {
                          height: 350,
                        },
                      },
                    },
                    {
                      breakpoint: 1441,
                      options: {
                        chart: {
                          height: 350,
                        },
                      },
                    },
                    {
                      breakpoint: 2561,
                      options: {
                        chart: {
                          height: 400,
                        },
                      },
                    },
                    {
                      breakpoint: 2561,
                      options: {
                        chart: {
                          height: 700,
                        },
                      },
                    },
                  ],
                }
                              this.dataalltopicUuid.push(obj)
              });
              
        }
      }
    )
  }
  updateSingleChecked(data:any):void{
    if (this.listhidetopic.findIndex(item => item == data) === -1 ){
      this.listhidetopic.push(data)
    }else{
      this.listhidetopic.splice(this.listhidetopic.findIndex(item => item == data),1)
    }
  }

  loadPie() {
    this.pieSentiment = new Array(4).fill({}).map((_, index) => {
      return {
        series: [80, 10, 10],
        labels: ['positive', 'neutral', 'negative'],
        chart: {
          width: '100%',
          height: 330,
          type: 'donut'
        },
        colors: pieOptions.colors,
        fill: pieOptions.fill,
        stroke: pieOptions.stroke,
        plotOptions: {
          pie: {
            startAngle: 0,
            expandOnClick: false,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: pieOptions.plotOptions.pie.dataLabels,
            donut: {
              size: pieOptions.plotOptions.pie.donut.size,
              background: pieOptions.plotOptions.pie.donut.background,
              labels: {
                show: true,
                name: pieOptions.plotOptions.pie.donut.labels.name,
                value: pieOptions.plotOptions.pie.donut.labels.value,
                total: {
                  show: true,
                  showAlways: true,
                  label: `Brand ${index}`,
                  fontSize:  pieOptions.plotOptions.pie.donut.labels.total.fontSize,
                  fontFamily: pieOptions.plotOptions.pie.donut.labels.total.fontFamily,
                  fontWeight:  pieOptions.plotOptions.pie.donut.labels.total.fontWeight,
                  color:  pieOptions.plotOptions.pie.donut.labels.total.color,
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        legend: pieOptions.legend.show = false,
        dataLabels: pieOptions.dataLabels,
        tooltip: pieOptions.tooltip,
        responsive: [
          {
            breakpoint: 450,
            options: {
              chart: {
                width: '100%',
                height: 250,
              },
            },
          },
          {
            breakpoint: 1025,
            options: {
              chart: {
                width: '100%',
                height: 280,
              },
            },
          },
          {
            breakpoint: 1441,
            options: {
              chart: {
                width: '100%',
                height: 330,
              },
              dataLabels: {
                enabled: true
              }
            },
          },
        ],
      }
    });
  }

  loadAdValue() {
    this.contentSvc.postadvalue().subscribe(
      result => {
        if (result){
          const datalabel = []
          const dataseries = []
          result.forEach(element => {
              datalabel.push(element.brand)
              dataseries.push(element.advlue)
          });
          this.pieAdValue = {
            series: dataseries,
            colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#9b5de5', '#ff6392', '#e76f51'],
            chart: {
              height: '100%',
              width: '100%',
              type: "pie",
              startRange: 0,
              events: {
                dataPointSelection: (event, chartContext, config) => {
                  this.loadDetailPie(datalabel[config.dataPointIndex])
                }
              }
            },
            labels: datalabel,
            plotOptions: {
              pie: {
                startAngle: 0,
                endAngle: 270,
              },
            },
      
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ['#edf2f4']
              }
            },
            tooltip: {
              fillSeriesColor: false,
              fillColors: '#36414c',
              y: {
                formatter: function (val) {
                  return 'IDR \n' + val.toLocaleString('en');
                },
                title: {
                  formatter: function (seriesName) {
                    return seriesName + ':';
                  },
                },
              },
            },
            stroke: pieOptions.stroke,
            legend: {
              show: true,
              position: 'bottom',
              floating: false,
              offsetX: 0,
              offsetY: 0,
              labels: pieOptions.legend.labels,
              markers: pieOptions.legend.markers,
              itemMargin: {
                horizontal: 5,
                vertical: 5
              }
            },
            responsive: [
              {
                breakpoint: 900,
                options: {
                  chart: {
                    width: '100%',
                    height: 150,
                  },
                },
              },
              {
                breakpoint: 1025,
                options: {
                  chart: {
                    width: '100%',
                    height: 250,
                  },
                },
              },
              {
                breakpoint: 1441,
                options: {
                  chart: {
                    width: '100%',
                    height: 300,
                  },
                  dataLabels: {
                    enabled: true
                  }
                },
              },
            ],
          };
        }
      }
    )
    
    // this.pieAdValue = {
    //   series: [600000, 502330, 250352, 65000],
    //   labels: this.tabsTitle,
    //   chart: {
    //     width: pieOptions.chart.width,
    //     height: 324,
    //     type: 'pie'
    //   },
    //   colors: pieOptions.colors,
    //   fill: pieOptions.fill,
    //   stroke: {
    //     width: 1,
    //     colors: pieOptions.stroke.colors
    //   },
    //   plotOptions: {
    //     pie: {
    //       startAngle: 0,
    //       expandOnClick: false,
    //       offsetX: 0,
    //       offsetY: 0,
    //       customScale: 1,
    //       dataLabels: pieOptions.plotOptions.pie.dataLabels,
    //       donut: {
    //         size: pieOptions.plotOptions.pie.donut.size,
    //         background: pieOptions.plotOptions.pie.donut.background,
    //         labels: {
    //           show: false,
    //         },
    //       },
    //     },
    //   }, 
    //   legend: pieOptions.legend,
    //   dataLabels: pieOptions.dataLabels,
    //   tooltip: pieOptions.tooltip,
    //   responsive: [
    //     {
    //       breakpoint: 450,
    //       options: {
    //         chart: {
    //           width: '100%',
    //           height: 150,
    //         },
    //       },
    //     },
    //     {
    //       breakpoint: 1025,
    //       options: {
    //         chart: {
    //           width: '100%',
    //           height: 274,
    //         },
    //       },
    //     },
    //     {
    //       breakpoint: 1441,
    //       options: {
    //         chart: {
    //           width: '100%',
    //           height: 340,
    //         },
    //         dataLabels: {
    //           enabled: true
    //         }
    //       },
    //     },
    //   ],
    // }
  }
  showModalDetail(item) {
    this.modalDetailData = [
      {
        title: item.headline,
        summary: item.summary,
        media: item.media,
        date: item.date
      }
    ];
    this.modalDetail = true;
    this.modalVisible = false;
  }
  loadDetailPie(data):void{
    const payload = {
      "brand": data,
      "sdate": "2021-04-01",
    "edate": "2021-04-08",
      "page": 0
  }
  this.formedmodalList = []
  this.contentSvc.postadvalueDetail(payload).subscribe(
    result => {
      result.data.forEach(element => {
        const dataobj = {
          headline: element.title,
          summary: element.content,
          media: element.media_name,
          date: element.created_at
        }
        this.formedmodalList.push(dataobj)
      });
      this.formalshow = true
      this.sosialshow = false
      this.modalVisible = true   
    }
  )
  }
  hideData(){
    const payload = {
      "topic": this.listhidetopic,
      "old_topic": this.labeltopicselected,
      "type_key": "wordcloud"
  }
  this.contentSvc.posthiddenTopic(payload).subscribe(
    result => {
      if (result){
        this.dataalltopicUuid =[]
          this.loadAssociate();
          this.listhidetopic = []
          this.labeltopicselected = []
      }
    }
  )
  }
}
