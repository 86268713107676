import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public title = new BehaviorSubject('Title');
  public color = new BehaviorSubject('Color');

  constructor() {}

  setTitle(title) {
    this.title.next(title);
  }

  setColor(color) {
    this.color.next(color);
  }
}
