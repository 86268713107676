<div class="icon-component">
  <i
    nz-icon
    nzType="{{ name }}"
    nzTheme="{{ theme }}"
    [ngClass]="{ icon: true }"
    [ngStyle]="{ 'font-size': size, color: color }"
  >
  </i>
</div>
