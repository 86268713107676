export const RoutesItem = [
  {
    level: 1,
    title: "Customer Experience",
    icon: "home",
    link: 'customer',
  },
  {
    level: 1,
    title: 'Employee Engagement',
    icon: 'user',
    link: 'employee',
  },
  {
    level: 1,
    title: "Media Perception",
    icon: "read",
    link: 'media',
  },
  {
    level: 1,
    title: 'Brand Tracking',
    icon: 'bar-chart',
    link: 'brand',
  },
  {
    level: 1,
    title: 'Competitor Landscape',
    icon: 'warning',
    link: 'competitor',
  },

];