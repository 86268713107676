import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'card[nz-card]',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() title: string;
  @Input() color: string;
  @Input() size: string = 'small';
  constructor() {}

  ngOnInit(): void {}
}
