import { Component, OnInit } from '@angular/core';
import { HeaderService } from '@services/header.service';
import * as Highcharts from 'highcharts';
declare var require: any;
const Wordcloud = require('highcharts/modules/wordcloud');
Wordcloud(Highcharts);

import { positiveList } from '@core/mock/listData';
import { ContentService } from "@services/content.service";
import * as moment from 'moment';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { JsonPipe } from '@angular/common';
import { LifecycleHooks } from '@angular/compiler/src/lifecycle_reflector';
import { Subject, Subscription } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  baseColor = '#ffe66d';

  // Modal
  modalVisible = false;
  modalDetail = false;

  sosialmodalList = [];
  formedmodalList = [];
  modalDetailData = [];

  dataPositive = positiveList;
  showfeedback: boolean = false;
  Highcharts = Highcharts;
  feedbackOptions: any;
  wordcloudOptions: any;
  opinionOptions = {};
  seriesdatacustomerfeedbackmap = [];
  showopinion: boolean = false;
  listdatacustomerpainpoint = [];
  listdatacustomersatisfac = [];
  date = {
    edate: new Date(new Date()).toISOString().slice(0, 10),
    sdate: new Date(new Date(new Date().setDate(new Date().getDate() - 7))).toISOString().slice(0, 10)
  }
  showwordcloud: boolean = false;
  subscription: Subscription;
  subject = webSocket("wss://ifap.digivla.com:8091");
  // subject = webSocket('wss://echo.websocket.org')
  destroy$: Subject<boolean> = new Subject<boolean>();
  formalshow: boolean = false;
  sosialshow: boolean = false;
  dataallgetinit = [];
  constructor(private headerService: HeaderService, private contenSvc: ContentService) {
  }

  // Modal Click
  showModalList() {
    this.modalVisible = true;
  }

  loadwordclouddummy() {
    this.contenSvc.postdummywordcloud().subscribe(result => {
      if (result) {
        let dataseries = [];
        result.data.forEach(element => {
          const datainit = {
            name: element.key,
            weight: element.doc_count,
            color: element.color
          }

          if (datainit.weight >= 50) {
            datainit.color = "#06d6a0"

          } else if (datainit.weight < 50) {
            datainit.color = "#ff6b6b"
          }
          dataseries.push(datainit)
        });
        this.wordcloudOptions = {
          chart: {
            backgroundColor: null,
            style: {
              fontFamily: 'Lato, sans-serif',
            },
          },
          legend: {
            enabled: false,
          },
          title: {
            text: null,
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                format: '{point.name}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                  fontSize: 12
                },
              },
            }
          },

          credits: {
            enabled: false,
          },

          exporting: {
            enabled: false,
          },
          series: [{
            type: 'wordcloud',
            data: dataseries,
            name: 'value'
          }]
        };
      }
      this.showwordcloud = true
    })
  }
  loadcustomerfeedbackmapdummy() {
    const dataclear = []
    this.contenSvc.postdummycustomerfeedbackmap().subscribe(data => {
      if (data) {
        data.data.customer_feedback_map.forEach(element => {
          dataclear.push({ data: [{ x: element.post_count, y: element.opinion_index, name: element.product }] })
        });
        this.loaddummycontentcustomersatisfac()
        this.loaddummycontentcustomerpainpoint()
        this.loaddummycustomeropinionindex(data)
        this.feedbackOptions = {
          colors: ['#00bbf9'],
          series: dataclear,
          chart: {
            type: 'bubble',
            backgroundColor: null,
            style: {
              fontFamily: 'Lato, sans-serif',
            },
          },

          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                format: '{point.name}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
            },
            bubble: {
              displayNegative: true,
              negativeColor: '#ff6b6b',
              minSize: 20,
              maxSize: 70,
              // zMin: -200,
              // zMax: 200,
              events: {
                click: (e) => {
                  this.loadcontentfeedbackmap(e.point.name)
                }
              }
            },
          },

          credits: {
            enabled: false,
          },

          exporting: {
            enabled: false,
          },

          legend: {
            enabled: false,
          },

          title: {
            text: null,
          },

          xAxis: {
            // min: -100,
            // max: 100,
            gridLineWidth: 1,
            gridLineDashStyle: 'longdash',
            title: {
              text: 'Mention Number',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#ffe66d',
              },
            },
            labels: {
              format: '{value}',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#edf2f4',
              },
            },
          },

          yAxis: {
            startOnTick: false,
            endOnTick: false,
            gridLineWidth: 0,
            // min: -100,
            // max: 100,
            title: {
              text: 'Opinion Index',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#ffe66d',
              },
            },
            labels: {
              format: '{value}',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#edf2f4',
              },
            },
            plotLines: [
              {
                color: '#edf2f4',
                width: 1,
                value: 0,
                zIndex: 3,
              },
            ],
            plotBands: [
              {
                color: 'rgba(255, 107, 107, 0.3)',
                from: 0,
                to: -200,
              },
            ],
          },
          tooltip: {
            shared: false,
            animation: true,
            backgroundColor: 'transparent',
            borderWidth: 0,
            shadow: false,
            style: {
              color: '#edf2f4',
              padding: 0,
            },
            useHTML: true,
            formatter: function () {
              return (
                '<div class="tooltip">' +
                '<div class="tooltip-title">' +
                this.point.name +
                '</div>' +
                // '<div class="tooltip-content"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio similique eos qui maxime deleniti temporibus obcaecati voluptatum, fugit pariatur adipisci aliquid ea, ipsam magni esse. Aliquam praesentium facilis sit repellendus quas harum animi inventore, maxime dignissimos quasi dolor quis ab corrupti porro, cum omnis nobis ipsa illum amet molestias voluptate!' +
                '</div>' +
                '</div>'
              );
            },
          },
        };
        this.showfeedback = true
      }
    })
  }
  showModalDetail(item) {
    this.modalDetailData = [
      {
        title: item.headline,
        summary: item.summary,
        media: item.media,
        date: item.date
      }
    ];
    this.modalDetail = true;
    this.modalVisible = false;
  }

  handleCancel(): void {
    this.modalVisible = false;
    this.modalDetail = false;
  }

  handleBack(): void {
    this.modalDetail = false;
    this.modalVisible = true;
  }

  ngOnInit(): void {
    this.loadcustomerfeedbackmapdummy()
    // this.postdatacustomerfeedbackMap();
    // this.postcustomeropinionIndex();
    // this.loaddummycustomeropinionindex()
    // this.postdatawordcloud();
    this.loadwordclouddummy()
    this.headerService.setTitle('customer experience');
    this.headerService.setColor(this.baseColor);
    this.subject.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.date['edate'] = data['end']
      this.date['sdate'] = data['start']
      this.loadcustomerfeedbackmapdummy()
      // this.postdatacustomerfeedbackMap();
      // this.postcustomeropinionIndex();
      // this.loaddummycustomeropinionindex()
      // this.postdatawordcloud();
      this.loadwordclouddummy()
    });
  }

  postdatawordcloud() {
    this.contenSvc.postcustomerwordcloud(this.date).subscribe(
      result => {
        if (result) {
          let dataseries = [];
          result.forEach(element => {
            const datainit = {
              name: element.key,
              weight: element.doc_count,
              color: element.color
            }

            if (datainit.weight >= 50) {
              datainit.color = "#06d6a0"

            } else if (datainit.weight < 50) {
              datainit.color = "#ff6b6b"
            }
            dataseries.push(datainit)
          });
          this.wordcloudOptions = {
            chart: {
              backgroundColor: null,
              style: {
                fontFamily: 'Lato, sans-serif',
              },
            },
            legend: {
              enabled: false,
            },
            title: {
              text: null,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  format: '{point.name}',
                  style: {
                    fontFamily: 'Lato, sans-serif',
                    color: '#edf2f4',
                    fontSize: 12
                  },
                },
              }
            },

            credits: {
              enabled: false,
            },

            exporting: {
              enabled: false,
            },
            series: [{
              type: 'wordcloud',
              data: dataseries,
              name: 'value'
            }]
          };
        }
        this.showwordcloud = true
      }
    )

  }
  postdatacustomerfeedbackMap() {
    const dataall = []
    const dataclear = []
    const getdataclear = []
    this.contenSvc.postcustomerfeedbackMap(this.date).subscribe(data => {
      data.forEach(element => {
        if (element.keywords.length > 0) {
          element.keywords.forEach(element => {
            if (!dataall.includes(JSON.stringify(element))) {
              dataall.push(JSON.stringify(element))
            }
          });
        }
      });
      dataall.forEach((item) => {
        let zuse: any;
        if (JSON.parse(item).sum_opinion_idx < 0) {
          zuse = -1
        } else {
          zuse = 1
        }
        getdataclear.push(JSON.parse(item))
        dataclear.push({ data: [{ x: JSON.parse(item).doc_count, y: JSON.parse(item).sum_opinion_idx, z: zuse, name: JSON.parse(item).keyword }] })
      })
      getdataclear.sort((a, b) => {
        return b.sum_opinion_idx - a.sum_opinion_idx;
      });
      this.postcontentcustomersatisfac(getdataclear[0].keyword)
      if (data) {
        this.feedbackOptions = {
          colors: ['#00bbf9'],
          series: dataclear,
          chart: {
            type: 'bubble',
            backgroundColor: null,
            style: {
              fontFamily: 'Lato, sans-serif',
            },
          },

          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                format: '{point.name}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
            },
            bubble: {
              displayNegative: true,
              negativeColor: '#ff6b6b',
              minSize: 20,
              maxSize: 70,
              // zMin: -200,
              // zMax: 200,
              events: {
                click: (e) => {
                  this.loadcontentfeedbackmap(e.point.name)
                }
              }
            },
          },

          credits: {
            enabled: false,
          },

          exporting: {
            enabled: false,
          },

          legend: {
            enabled: false,
          },

          title: {
            text: null,
          },

          xAxis: {
            // min: -100,
            // max: 100,
            gridLineWidth: 1,
            gridLineDashStyle: 'longdash',
            title: {
              text: 'Mention Number',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#ffe66d',
              },
            },
            labels: {
              format: '{value}',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#edf2f4',
              },
            },
          },

          yAxis: {
            startOnTick: false,
            endOnTick: false,
            gridLineWidth: 0,
            // min: -100,
            // max: 100,
            title: {
              text: 'Opinion Index',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#ffe66d',
              },
            },
            labels: {
              format: '{value}',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#edf2f4',
              },
            },
            plotLines: [
              {
                color: '#edf2f4',
                width: 1,
                value: 0,
                zIndex: 3,
              },
            ],
            plotBands: [
              {
                color: 'rgba(255, 107, 107, 0.3)',
                from: 0,
                to: -200,
              },
            ],
          },
          tooltip: {
            shared: false,
            animation: true,
            backgroundColor: 'transparent',
            borderWidth: 0,
            shadow: false,
            style: {
              color: '#edf2f4',
              padding: 0,
            },
            useHTML: true,
            formatter: function () {
              return (
                '<div class="tooltip">' +
                '<div class="tooltip-title">' +
                this.point.name +
                '</div>' +
                // '<div class="tooltip-content"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio similique eos qui maxime deleniti temporibus obcaecati voluptatum, fugit pariatur adipisci aliquid ea, ipsam magni esse. Aliquam praesentium facilis sit repellendus quas harum animi inventore, maxime dignissimos quasi dolor quis ab corrupti porro, cum omnis nobis ipsa illum amet molestias voluptate!' +
                '</div>' +
                '</div>'
              );
            },
          },
        };
        this.showfeedback = true
      }
    })
  }
  loadcontentfeedbackmap(data) {
    this.formalshow = true
    this.sosialshow = false
    this.modalVisible = true
    this.date['keyword'] = data
    this.formedmodalList = []
    const obj = 'start_date=2020-05-11&end_date=2021-05-11&client=bni&page=0&size=100&selected_topic='+data
    this.contenSvc.postdummycontent(obj).subscribe(
      result => {
        if (result){
                result.data.forEach(element => {
            if (element.platform === 'fb' || element.platform === 'yt' || element.platform === 'ig') {
              element['nameuse'] = element.user.name
              element['linkuse'] = element.user.post_url
            } else {
              element['nameuse'] = element.user.screen_name
              element['linkuse'] = 'twitter.com' + element.user.post_url
            }
            console.log(element);
            const dataobj = {
              headline: element.user.name,
              summary: element.text,
              media: element.platform,
              date: element.created_at
            }
            this.formedmodalList.push(dataobj)
          });
        }
      }
    )
  }
  loaddummycustomeropinionindex(data: any) {
    let dataseries = []
    let date = []
    const dataall = [];
    const dateall = []
    data.data.customer_opinion_index.forEach(element => {
      if (element.data.length > 0) {
        let datapushall = []
        dataall.push(element)
        let dataobj = {
          name: element.product,
          marker: {
            symbol: 'circle',
          },
        }
        for (const iterator of element.data) {
          datapushall.push(iterator.doc_count)
          dataobj['data'] = datapushall
          dateall.push(iterator)
        }
        dataseries.push(dataobj)
      }
    });
    dateall.sort((a, b) => {
      return a.doc_count - b.doc_count;
    });
    dataall.forEach(item => {
      item.data.forEach(element => {
        if (element === dateall[0]) {
          // dataselect.push(item.topic_uuid)
        }
      });
    })
    dataall[0].data.forEach(element => {
      date.push(moment(element.date).format().split('T')[0])
    });
    this.opinionOptions = {
      colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#9b5de5', '#ff6392'],
      series: dataseries,
      chart: {
        type: 'line',
        backgroundColor: null,
        style: {
          fontFamily: 'Lato, sans-serif',
          color: '#edf2f4',
        },
      },

      title: {
        text: null,
      },

      credits: {
        enabled: false,
      },

      exporting: {
        enabled: false,
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
          marker: {
            symbol: 'circle',
            lineColor: null,
            lineWidth: 2,
          },
          point: {
            events: {
              click: (e) => {
                this.loadcontentopinion(e.point.category, e.point.series.name);
              }
            }
          }
        },
      },
      yAxis: {
        gridLineWidth: 0,
        gridLineDashStyle: 'longdash',
        labels: {
          format: ' {value}',
          style: {
            fontFamily: 'Lato, sans-serif',
            color: '#edf2f4',
          },
        },
        title: {
          text: null,
        },
      },

      xAxis: {
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        type: 'datetime',
        title: {
          text: null,
        },
        labels: {
          align: 'center',
          format: '{value:%b}',
          style: {
            fontFamily: 'Lato, sans-serif',
            color: '#edf2f4',
          },
        },
        categories: date,
      },
      tooltip: {
        shared: true,
        animation: true,
        backgroundColor: 'transparent',
        borderWidth: 0,
        shadow: false,
        style: {
          color: '#edf2f4',
          fontFamily: 'Lato, sans-serif',
        },
        useHTML: true,
        headerFormat:
          '<div class="tooltip share"><div class="tooltip-title"> {point.key} </div>',
        pointFormat:
          '<div class="tooltip-content share"><div class="series-name""><span class="dot" style="background-color: {series.color};"></span>{series.name}:<span class="series-value">{point.y}</span></div></div>',
        footerFormat: '</div>',
      },

      legend: {
        enabled: true,
        itemStyle: {
          color: '#edf2f4',
          fontFamily: 'Lato, sans-serif',
        },
        itemHoverStyle: {
          color: '#495867',
        },
      },
    };
    this.showopinion = true
  }
  postcustomeropinionIndex() {
    this.contenSvc.postcustomerfeedbackMap(this.date).subscribe(
      result => {
        this.dataallgetinit = result;
        if (result) {
          let dataseries = []
          let date = []
          const dataall = [];
          const dateall = []
          const dataselect = [];
          result.forEach(element => {
            if (element.datetopic.length > 0) {
              let datapushall = []
              dataall.push(element)
              let dataobj = {
                name: element.topic,
                marker: {
                  symbol: 'circle',
                },
              }
              for (const iterator of element.datetopic) {
                datapushall.push(iterator.value)
                dataobj['data'] = datapushall
                dateall.push(iterator)
              }
              dataseries.push(dataobj)
            }
          });
          dateall.sort((a, b) => {
            return a.value - b.value;
          });
          dataall.forEach(item => {
            item.datetopic.forEach(element => {
              if (element === dateall[0]) {
                dataselect.push(item.topic_uuid)
              }
            });
          })
          // this.postcontentcustomerpainpoint(dateall[0].time,dataselect[0])
          dataall[0].datetopic.forEach(element => {
            date.push(moment(element.time).format().split('T')[0])
          });
          this.opinionOptions = {
            colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#9b5de5', '#ff6392'],
            series: dataseries,
            chart: {
              type: 'line',
              backgroundColor: null,
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#edf2f4',
              },
            },

            title: {
              text: null,
            },

            credits: {
              enabled: false,
            },

            exporting: {
              enabled: false,
            },

            plotOptions: {
              series: {
                dataLabels: {
                  enabled: false,
                },
                marker: {
                  symbol: 'circle',
                  lineColor: null,
                  lineWidth: 2,
                },
                point: {
                  events: {
                    click: (e) => {
                      this.loadcontentopinion(e.point.category, e.point.series.name);
                    }
                  }
                }
              },
            },
            yAxis: {
              gridLineWidth: 0,
              gridLineDashStyle: 'longdash',
              labels: {
                format: ' {value}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
              title: {
                text: null,
              },
            },

            xAxis: {
              gridLineWidth: 1,
              gridLineDashStyle: 'longdash',
              type: 'datetime',
              title: {
                text: null,
              },
              labels: {
                align: 'center',
                format: '{value:%b}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
              categories: date,
            },
            tooltip: {
              shared: true,
              animation: true,
              backgroundColor: 'transparent',
              borderWidth: 0,
              shadow: false,
              style: {
                color: '#edf2f4',
                fontFamily: 'Lato, sans-serif',
              },
              useHTML: true,
              headerFormat:
                '<div class="tooltip share"><div class="tooltip-title"> {point.key} </div>',
              pointFormat:
                '<div class="tooltip-content share"><div class="series-name""><span class="dot" style="background-color: {series.color};"></span>{series.name}:<span class="series-value">{point.y}</span></div></div>',
              footerFormat: '</div>',
            },

            legend: {
              enabled: true,
              itemStyle: {
                color: '#edf2f4',
                fontFamily: 'Lato, sans-serif',
              },
              itemHoverStyle: {
                color: '#495867',
              },
            },
          };
          this.showopinion = true
        }
      }
    )
  }
  loadcontentopinion(date: any, topic: any) {
    this.formalshow = true
    this.sosialshow = false
    this.modalVisible = true
    this.sosialmodalList = []
    const obj = 'start_date='+date+'&end_date='+date+'&client=bni&page=0&size=100&selected_topic='+topic
    this.contenSvc.postdummycontent(obj).subscribe(
      result => {
        if (result){
                result.data.forEach(element => {
            if (element.platform === 'fb' || element.platform === 'yt' || element.platform === 'ig') {
              element['nameuse'] = element.user.name
              element['linkuse'] = element.user.post_url
            } else {
              element['nameuse'] = element.user.screen_name
              element['linkuse'] = 'twitter.com' + element.user.post_url
            }
            const dataobj = {
              headline: element.user.name,
              summary: element.text,
              media: element.platform,
              date: element.created_at
            }
            this.formedmodalList.push(dataobj)
          });
        }
      }
    )
    // this.dataallgetinit.forEach(data => {
    //   if (data.topic == topic) {
    //     const obj = 'start_date='+date+'&end_date='+date+'&client=bni&page=0&size=100&selected_topic='+data
        
        // this.contenSvc.postcontentcustomeropinionIndex(dateuse).subscribe(
        //   result => {
        //     if (result) {
        //       result.data.forEach(element => {
        //         if (element.platform === 'fb' || element.platform === 'yt' || element.platform === 'ig') {
        //           element['nameuse'] = element.user.name
        //           element['linkuse'] = element.user.post_url
        //         } else {
        //           element['nameuse'] = element.user.screen_name
        //           element['linkuse'] = 'twitter.com' + element.user.post_url
        //         }
        //         if (!element['nameuse'].includes('link rel')) {
        //           const dataobj = {
        //             headline: element.user.name,
        //             summary: element.text,
        //             media: element.platform,
        //             date: element.created_at
        //           }
        //           this.sosialmodalList.push(dataobj)
        //         }
        //       });
        //     }
        //   }
        // )
    //   }
    // })
  }

  postcontentcustomerpainpoint(date: any, topic_uuid: any) {
    const dateuse = {
      edate: date,
      sdate: date,
      topic_uuid: topic_uuid
    }
    this.contenSvc.postcontentcustomeropinionIndex(dateuse).subscribe(
      result => {
        if (result) {
          result.data.forEach(element => {
            if (element.platform === 'fb' || element.platform === 'yt' || element.platform === 'ig') {
              element['nameuse'] = element.user.name
              element['linkuse'] = element.user.post_url
            } else {
              element['nameuse'] = element.user.screen_name
              element['linkuse'] = 'twitter.com' + element.user.post_url
            }
            if (!element['nameuse'].includes('link rel') && element.positive_opinion_index === 0) {
              this.listdatacustomerpainpoint.push(element)
            }
            this.listdatacustomerpainpoint.sort((a, b) => (b.likes_count > a.likes_count) ? 1 : -1)
          });
        }
      }
    )
  }
  loaddummycontentcustomerpainpoint() {
    this.contenSvc.postdummycustomerpainpoint().subscribe(
      result => {
        if (result) {
          result.data.forEach(element => {
            if (element.platform === 'facebook' || element.platform === 'youtube' || element.platform === 'instagram') {
              element['nameuse'] = element.user.name
              element['linkuse'] = element.user.post_url
            } else {
              element['nameuse'] = element.user.name
              element['linkuse'] = 'twitter.com' + element.user.post_url
            }
            this.listdatacustomerpainpoint.push(element)
          });
        }
      }
    )
  }
  loaddummycontentcustomersatisfac() {
    this.contenSvc.postdummycustomersatisfac().subscribe(
      result => {
        if (result) {
          result.data.forEach(element => {
            if (element.platform === 'facebook' || element.platform === 'youtube' || element.platform === 'instagram') {
              element['nameuse'] = element.user.name
              element['linkuse'] = element.user.post_url
            } else {
              element['nameuse'] = element.user.name
              element['linkuse'] = 'twitter.com' + element.user.post_url
            }
            this.listdatacustomersatisfac.push(element)
          });
        }
      }
    )
  }
  postcontentcustomersatisfac(keyword: any) {
    this.date['keyword'] = keyword
    this.contenSvc.postcontentcustomeropinionIndex(this.date).subscribe(
      result => {
        if (result) {
          result.data.forEach(element => {
            if (element.platform === 'fb' || element.platform === 'yt' || element.platform === 'ig') {
              element['nameuse'] = element.user.name
              element['linkuse'] = element.user.post_url
            } else {
              element['nameuse'] = element.user.screen_name
              element['linkuse'] = 'twitter.com' + element.user.post_url
            }
            if (!element['nameuse'].includes('link rel') && element.negative_opinion_index === 0) {
              this.listdatacustomersatisfac.push(element)
              this.listdatacustomersatisfac.sort((a, b) => (b.likes_count > a.likes_count) ? 1 : -1)
            }
          });
        }
      }
    )
  }
}
