import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ApiService } from '@app/services/api.service';
import { environment } from "../../environments/environment";
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ContentService {
  urlSocmed:string;
  urlFormed:string;
  tokenSocmed:string;
  tokenFormed:string;
  clientName:string;
  formedAll:number;
  formedCetak:number;
  formedOnline:number;
  formedTv:number;
  formedRadio:number;
  socmedTopicAll:string;
  brandfeedbackMap:any;
  constructor(
    private api: ApiService,
  ) {    
    

    for (let i =0;i < environment.user.length; i++){
      if (window.location.hostname.split('.')[0] == environment.user[i].id){
        this.urlSocmed = environment.user[i].urlsocmed
        this.urlFormed = environment.user[i].urlformed
        this.clientName = environment.user[i].clientname
        this.tokenFormed = environment.user[i].tokenformed
        this.tokenSocmed = environment.user[i].tokensocmed
        this.formedAll = environment.user[i].formedall
        this.formedCetak = environment.user[i].formedcetak
        this.formedTv = environment.user[i].formedtv
        this.formedOnline = environment.user[i].formedonline
        this.formedRadio = environment.user[i].formedradio
        this.socmedTopicAll = environment.user[i].socmedtopicall
        this.brandfeedbackMap = environment.user[i].brandfeedbackmap
      }
    }    
  }

 
  postcustomerfeedbackMap(data: any): Observable<any> {
    const url = this.urlSocmed + "mediacenter/topics/opinion-index";
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }


  postcustomeropinionIndex(data: any): Observable<any> {
    const url = this.urlSocmed + "mediacenter/topics/opinion-index";;
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postcustomerwordcloud(data: any): Observable<any> {
    const url = this.urlSocmed + "analytic/chart/generic";
    data['topic_uuid'] = this.socmedTopicAll
    data['type'] = 'word_cloud'
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postcontentcustomeropinionIndex(data: any): Observable<any> {
    const url = this.urlSocmed + "analytic/data-chart/generic";
    data['type'] = 'likes_count';
    data['page'] = 0;;
    data['size'] = 100;
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }

  postcontentbrand(data: any): Observable<any> {
    const url = 'https://digivla-sna.trinix.id/api/analytic/ifap/post';
    data['type'] = 'likes_count';
    data['page'] = 0;
    data['size'] = 100;
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  
  postcontentBymedia(data: any): Observable<any> {
    const url = this.urlFormed + "dashboard/article-by-tone";
    data['page'] = 0;
    data['maxSize'] = 10;
    data['category_id'] = "all"
    data['category_set'] =  "0"
    data['user_media_type_id'] =  this.formedAll
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenFormed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  

  postmedianewsArticle(data: any): Observable<any> {
    const url = this.urlFormed + 'user/editing/';
    data['category_id'] = 'all';
    data['category_set'] = '0';
    data['media_id'] = 0;
    data['order'] =  "desc";
    data['order_by'] =  "datee";
    data['page'] =  0;
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenFormed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postcontentEws(data: any): Observable<any> {
    const url = this.urlFormed + "user/editing/";
    data['category_set'] = '0';
    data['media_id'] = 0;
    data['order'] =  "desc";
    data['order_by'] =  "datee";
    data['page'] =  0;
    data['maxSize'] =  10;
    data['user_media_type_id'] =  this.formedAll
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenFormed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }

  postmediaTone(data: any): Observable<any> {
    const url = this.urlFormed + "dashboard/tone-by-media";
    data['category_id'] = 'all';
    data['category_set'] = '0';
    data['media_id'] = "0";
    data['user_media_type_id'] =  "0"
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenFormed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postmediapickupVolume(data: any): Observable<any> {
    const url = this.urlFormed + "dashboard/media-visibility";
    data['category_id'] = 'all';
    data['category_set'] = '0';
    data['media_id'] = "0";
    data['user_media_type_id'] =  "0"
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenFormed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postEws(data: any): Observable<any> {
    const url = this.urlFormed + "dashboard/tone-by-media-tier";
    data['category_id'] = 'all';
    data['category_set'] = '0';
    data['media_id'] = "0";
    data['user_media_type_id'] =  this.formedAll
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenFormed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }

  postbrandfeedbackmap(data: any): Observable<any> {
    const url = this.urlSocmed + "analytic/ifap/feedbackmap";
    data['brands'] = this.brandfeedbackMap
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postbrandcompetition(data: any): Observable<any> {
    const url = this.urlSocmed + "analytic/ifap/brandsentiment";
    data['brands'] = this.brandfeedbackMap
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  getfileuser(): Observable<any> {
    const url = this.urlSocmed + "analytic/ifap/doc/list";
    const payload = {
      "client": this.clientName
    }
    const body = JSON.stringify(payload);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postuploadfile(data: any): Observable<any> {
    const url = this.urlSocmed + "analytic/ifap/doc/upload";
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postdeletefile(data: any): Observable<any> {
    const url = this.urlSocmed + "analytic/ifap/doc/delete";
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  posttoptenFile(data: any): Observable<any> {
    const url = this.urlSocmed + "analytic/ifap/doc/top-ten-labels";
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postbrandCompetitor(data: any): Observable<any> {
    const url = this.urlSocmed + "analytic/chart/generic";
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  posthiddenTopic(data: any): Observable<any> {
    const url = this.urlSocmed + "analytic/topic/hidden/create";
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postadvalue(): Observable<any> {
    const url = this.urlSocmed + "analytic/ifap/advalue";
    const datapost = {
      sdate : "2021-04-01",
      edate : "2021-04-08"  
    }
    // data['brands'] = this.brandfeedbackMap
    const body = JSON.stringify(datapost);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postadvalueDetail(data:any): Observable<any> {
    const url = this.urlSocmed + "analytic/ifap/formed/articles";
    // data['brands'] = this.brandfeedbackMap
    const body = JSON.stringify(data);
    const tempHeader: any = {
      "Authorization": "Token " + this.tokenSocmed,
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.postData(url, body , headers);
  }
  postdummywordcloud(): Observable<any> {
    const url = "https://digivla-sna.trinix.id/api/analytic/ifap/dummy/wordcloud?client=bni";
    const tempHeader: any = {
      "Authorization": "Token gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv",
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.getData(url, headers);
  }
  postdummycustomerfeedbackmap(): Observable<any> {
    const url = "https://digivla-sna.trinix.id/api/analytic/ifap/dummy/posts/count?start_date=2021-05-01&end_date=2021-05-12&client=bni&other_field=opinion_index";
    const tempHeader: any = {
      "Authorization": "Token gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv",
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.getData(url, headers);
  }
  postdummybrandfeedbackmap(): Observable<any> {
    const url = "https://digivla-sna.trinix.id/api/analytic/ifap/dummy/posts/count?start_date=2021-05-01&end_date=2021-05-12&client=bni&other_field=brands";
    const tempHeader: any = {
      "Authorization": "Token gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv",
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.getData(url, headers);
  }
  postdummycustomersatisfac(): Observable<any> {
    const url = "https://digivla-sna.trinix.id/api/analytic/ifap/dummy/posts?start_date=2020-05-11&end_date=2021-05-11&client=bni&page=0&size=100&sentiment=positive&"+"sort_by=opinion_index";
    const tempHeader: any = {
      "Authorization": "Token gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv",
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.getData(url, headers);
  }
  postdummycustomerpainpoint(): Observable<any> {
    const url = "https://digivla-sna.trinix.id/api/analytic/ifap/dummy/posts?start_date=2020-05-11&end_date=2021-05-11&client=bni&page=0&size=100&sentiment=negative&"+"sort_by=opinion_index";
    const tempHeader: any = {
      "Authorization": "Token gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv",
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.getData(url, headers);
  }
  postdummycustomeropinionindex(): Observable<any> {
    const url = "https://digivla-sna.trinix.id/api/analytic/ifap/dummy/posts/count?start_date=2021-05-01&end_date=2021-05-12&client=bni&other_field=opinion_index";
    const tempHeader: any = {
      "Authorization": "Token gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv",
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.getData(url, headers);
  }
  postdummybrandassociation(): Observable<any> {
    const url = "https://digivla-sna.trinix.id/api/analytic/ifap/dummy/competitor/brands?client=bni&data_type=brands&start_date=2021-05-01&end_date=2021-05-12";
    const tempHeader: any = {
      "Authorization": "Token gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv",
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.getData(url, headers);
  }
  postdummycompetitorsentiment(): Observable<any> {
    const url = "https://digivla-sna.trinix.id/api/analytic/ifap/dummy/competitor/sentiments?client=bri&start_date=2021-04-11&end_date=2021-05-12";
    const tempHeader: any = {
      "Authorization": "Token gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv",
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.getData(url, headers);
  }
  postdummybrandassociationcompetitor(): Observable<any> {
    const url = "https://digivla-sna.trinix.id/api/analytic/ifap/dummy/competitor/brands?client=bri&data_type=brands&start_date=2021-05-01&end_date=2021-05-12";
    const tempHeader: any = {
      "Authorization": "Token gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv",
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.getData(url, headers);
  }
  postdummycontent(data:any): Observable<any> {
    const url = "https://digivla-sna.trinix.id/api/analytic/ifap/dummy/posts?"+data;
    const tempHeader: any = {
      "Authorization": "Token gcqSPdXbwRpIzLdDoEHDez5BTG3ETjtv",
      "Content-Type" : "application/json"
    };
    const headers = new HttpHeaders(tempHeader);
    return this.api.getData(url, headers);
  }
}
