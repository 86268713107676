<div
  nz-row
  [nzGutter]="[
    { xs: 8, sm: 10, md: 12, lg: 10, xl: 12, xxl: 16 },
    { xs: 8, sm: 10, md: 12, lg: 10, xl: 12, xxl: 16 }
  ]"
>
  <div nz-col nzXs="24" nzMd="24" nzLg="12" nzXl="12">
    <card nz-card title="media sentiment" color="#06d6a0">
      <div class="chart-component" *ngIf="show">
        <highcharts-chart class="feedback" [Highcharts]="Highcharts" [options]="sentimentOptions"></highcharts-chart>
      </div>
    </card>
  </div>
  <div nz-col nzXs="24" nzMd="24" nzLg="12" nzXl="12">
    <card nz-card title="media news & article" color="#06d6a0">
      <div
        nz-row
        [nzGutter]="[
          { xs: 8, sm: 10, md: 12, lg: 10, xl: 12, xxl: 16 },
          { xs: 8, sm: 10, md: 12, lg: 10, xl: 12, xxl: 16 }
        ]"
      >
        <div nz-col nzXs="24" nzMd="24" nzLg="12" nzXl="10">
          <div nz-row [nzGutter]="[{ xs: 8, sm: 10, md: 12, lg: 12, xl: 16 }]">
            <div nz-col [nzSpan]="24">
              <div class="list-wrap-media">
                <div class="empty-list" *ngIf="medialisttvget.length === 0">
                  <nz-empty [nzNotFoundContent]="contentEmpty">
                    <ng-template #contentEmpty>
                      <label style="color: #edf2f4; font-weight: 500"> Video Not Found </label>
                    </ng-template>
                  </nz-empty>
                </div>
                <div class="list-item" *ngFor="let item of medialisttvget">
                  <div class="list-head">
                    <div class="list-label">
                      <icon class="label-icon" name="youtube" theme="fill" size="15px" color="#edf2f4"></icon>
                      <a (click)="clicktype(item)">{{ item.title }}</a>
                    </div>
                  </div>
                  <div class="list-content-media">
                    <small>{{ item.media_name }}</small>
                    <small>{{ item.datee.split(" ")[0] }}</small>
                  </div>
                </div>
              </div>
            </div>
            <div nz-col [nzSpan]="24">
              <div class="list-wrap-media">
                <div class="empty-list" *ngIf="medialistarticleget.length === 0">
                  <nz-empty [nzNotFoundContent]="contentTpl">
                    <ng-template #contentTpl>
                      <label style="color: #edf2f4; font-weight: 500"> Article Not Found </label>
                    </ng-template>
                  </nz-empty>
                </div>
                <div class="list-item" *ngFor="let item of medialistarticleget">
                  <div class="list-head">
                    <div class="list-label">
                      <icon class="label-icon" name="file-text" theme="fill" size="15px" color="#edf2f4"></icon>
                      <a (click)="clicktype(item)">{{ item.title }}</a>
                    </div>
                  </div>
                  <div class="list-content-media">
                    <small>{{ item.media_name }}</small>
                    <small>{{ item.datee.split(" ")[0] }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nz-col nzXs="24" nzMd="24" nzLg="12" nzXl="14">
          <div class="view-wrap" *ngIf="preview === 'media tv'">
            <video class="video" [src]="filemediatv" #videoPlayer controls>
              Your browser does not support HTML5 video.
            </video>
          </div>
          <div class="view-wrap" *ngIf="preview === 'media online'">
            <p style="height: 100%; overflow-y: scroll; color: white; max-height: 240px">
              {{ filemediaonline }}
            </p>
          </div>
          <div class="view-wrap" *ngIf="preview === 'media cetak'">
            <iframe [src]="filemediacetak" frameBorder="0" scrolling="auto" height="100%" width="100%"></iframe>
          </div>
          <div class="view-wrap" *ngIf="preview === 'media radio'">
            <audio controls>
              <source [src]="filemediaradio" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      </div>
    </card>
  </div>
  <div nz-col nzXs="24" nzMd="24" nzLg="24" nzXl="24">
    <card nz-card title="media pickup volume" color="#06d6a0">
      <div class="chart-component" *ngIf="showmediapickupvolume">
        <highcharts-chart class="opinion" [Highcharts]="Highcharts" [options]="volumeOptions"></highcharts-chart>
      </div>
    </card>
  </div>
</div>

<modal nz-modal width="70%" title="article list" [visible]="modalVisible" closeable="false">
  <div content>
    <nz-tabset nzSize="small" nzTabPosition="top">
      <nz-tab nzTitle="Formal Media" *ngIf="formalshow">
        <div class="list-wrap-modal">
          <div class="list-item" *ngFor="let item of formedmodalList">
            <div class="list-head">
              <div class="list-label">
                <a (click)="showModalDetail(item)">{{ item.headline }}</a>
              </div>
            </div>
            <div class="list-content">
              {{ item.summary }}
            </div>
            <div class="list-info">
              <div class="info-item"><icon name="notification"></icon>{{ item.media }}</div>
              <div class="info-item"><icon name="calendar"></icon>{{ item.date }}</div>
            </div>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Social Media" *ngIf="sosialshow">
        <div class="list-wrap-modal">
          <div class="list-item" *ngFor="let item of sosialmodalList">
            <div class="list-head">
              <div class="list-label">
                <a (click)="showModalDetail(item)">{{ item.headline }}</a>
              </div>
            </div>
            <div class="list-content">
              {{ item.summary }}
            </div>
            <div class="list-info">
              <div class="info-item"><icon name="notification"></icon>{{ item.media }}</div>
              <div class="info-item"><icon name="calendar"></icon>{{ item.date }}</div>
            </div>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
  <div footer>
    <app-button
      nz-button
      type="dashed"
      class="line-danger"
      icon="close-circle"
      text="cancel"
      (click)="handleCancel()"
    ></app-button>
  </div>
</modal>

<modal nz-modal width="70%" title="article detail" [visible]="modalDetail" closeable="false">
  <div content *ngFor="let item of modalDetailData">
    <div class="modal-body-content">
      <div class="section-label">
        <div class="modal-section label-col">
          <div class="modal-item">
            <div class="item-title">title</div>
            <span>{{ item.title }}</span>
          </div>
          <div class="modal-item">
            <div class="item-title">date</div>
            <span>{{ item.date }}</span>
          </div>
        </div>
        <div class="modal-section label-col">
          <div class="modal-item">
            <div class="item-title">media</div>
            <span>{{ item.media }}</span>
          </div>
        </div>
      </div>
      <div class="section-text">
        {{ item.summary }}
      </div>
    </div>
  </div>
  <div footer>
    <app-button
      nz-button
      type="dashed"
      class="line-info"
      icon="left-circle"
      text="back"
      (click)="handleBack()"
    ></app-button>
    <app-button
      nz-button
      type="dashed"
      class="line-danger"
      icon="close-circle"
      text="cancel"
      (click)="handleCancel()"
    ></app-button>
  </div>
</modal>
