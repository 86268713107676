<div
  nz-row
  [nzGutter]="[
    { xs: 8, sm: 10, md: 12, lg: 10, xl: 12, xxl: 16 },
    { xs: 8, sm: 10, md: 12, lg: 10, xl: 12, xxl: 16 }
  ]"
>
  <!-- <div nz-col nzXs="24" nzMd="24" nzLg="24" nzXl="24" *ngIf="uploadfiletriger">
    <card nz-card title="Submit The Document" [color]="baseColor">
      <input type="file" (change)="onFileChange($event)" style="color: #edf2f4" />
    </card>
  </div>

  <div nz-col nzXs="24" nzMd="24" nzLg="24" nzXl="24" *ngIf="listOfData.length > 0 && uploadfiletriger">
    <card nz-card title="List Files" [color]="baseColor">
      <nz-table #editRowTable nzBordered [nzData]="listOfData">
        <thead>
          <tr>
            <th>Name</th>
            <th>File</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of editRowTable.data" class="editable-row">
            <td>
              <div class="editable-cell">
                {{ data.name }}
              </div>
            </td>
            <td>{{ data.file }}</td>
            <td>
              <a nz-popconfirm nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteRow(data.file)">Delete</a>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <button (click)="renderFile()" style="margin-top: 10px">Render File</button>
    </card>
  </div> -->
  <!-- ========== -->
  <!-- <div nz-col nzXs="24" nzMd="24" nzLg="24" nzXl="24" *ngIf="!uploadfiletriger">
    <button (click)="editFile()" style="margin-top: 10px" *ngIf="!uploadfiletriger">Update File</button>
  </div> -->
  <div nz-col nzXs="24" nzMd="24" nzLg="12" nzXl="12">
    <card nz-card title="brand feedback map" [color]="baseColor">
      <div class="chart-component" *ngIf="showbrandfeedbackmap">
        <highcharts-chart class="feedback" [Highcharts]="Highcharts" [options]="volumeOptions"></highcharts-chart>
      </div>

      <div extra *ngIf="!uploadfiletriger">
        <a
          style="min-width: 0px"
          nz-button
          nzSize="small"
          nzType="link"
          [ngStyle]="{ color: baseColor }"
          (click)="editFile()"
          *ngIf="!uploadfiletriger"
        >
          <i nz-icon nzType="appstore-add" nzTheme="outline"></i>
        </a>
      </div>
    </card>
  </div>
  <div nz-col nzXs="24" nzMd="24" nzLg="12" nzXl="12">
    <card nz-card title="early warning system" [color]="baseColor">
      <div class="chart-component" *ngIf="loadews">
        <highcharts-chart class="feedback" [Highcharts]="Highcharts" [options]="statusOptions"></highcharts-chart>
      </div>
      <div extra *ngIf="!uploadfiletriger">
        <a
          style="min-width: 0px"
          nz-button
          nzSize="small"
          nzType="link"
          [ngStyle]="{ color: baseColor }"
          (click)="editFile()"
          *ngIf="!uploadfiletriger"
        >
          <i nz-icon nzType="appstore-add" nzTheme="outline"></i>
        </a>
      </div>
    </card>
  </div>
  <!-- ========== -->
  <div nz-col nzXs="24" nzMd="24" nzLg="15" nzXl="18">
    <card nz-card title="brand association" [color]="baseColor">
      <div class="chart-component">
        <apx-chart
          *ngIf="treeMapOptions?.series"
          class="opinion"
          [series]="treeMapOptions.series"
          [chart]="treeMapOptions.chart"
          [colors]="treeMapOptions.colors"
          [fill]="treeMapOptions.fill"
          [grid]="treeMapOptions.grid"
          [dataLabels]="treeMapOptions.dataLabels"
          [plotOptions]="treeMapOptions.plotOptions"
          [legend]="treeMapOptions.legend"
          [responsive]="treeMapOptions.responsive"
        ></apx-chart>
      </div>
      <div extra *ngIf="!uploadfiletriger">
        <a
          style="min-width: 0px"
          nz-button
          nzSize="small"
          nzType="link"
          [ngStyle]="{ color: baseColor }"
          (click)="editFile()"
          *ngIf="!uploadfiletriger"
        >
          <i nz-icon nzType="appstore-add" nzTheme="outline"></i>
        </a>
      </div>
    </card>
  </div>
  <div nz-col nzXs="24" nzMd="24" nzLg="9" nzXl="6">
    <card nz-card title="brand ad value" [color]="baseColor">
      <div class="chart-component">
        <apx-chart
          class="pie-brand"
          [series]="pieChart?.series"
          [chart]="pieChart?.chart"
          [colors]="pieChart?.colors"
          [stroke]="pieChart?.stroke"
          [dataLabels]="pieChart?.dataLabels"
          [tooltip]="pieChart?.tooltip"
          [plotOptions]="pieChart?.plotOptions"
          [labels]="pieChart?.labels"
          [responsive]="pieChart?.responsive"
          [legend]="pieChart?.legend"
        ></apx-chart>
      </div>
      <div extra *ngIf="!uploadfiletriger">
        <a
          style="min-width: 0px"
          nz-button
          nzSize="small"
          nzType="link"
          [ngStyle]="{ color: baseColor }"
          (click)="editFile()"
          *ngIf="!uploadfiletriger"
        >
          <i nz-icon nzType="appstore-add" nzTheme="outline"></i>
        </a>
      </div>
    </card>
  </div>
</div>

<modal nz-modal width="70%" title="article list" [visible]="modalVisible" closeable="false">
  <div content>
    <nz-tabset nzSize="small" nzTabPosition="top">
      <nz-tab nzTitle="Formal Media" *ngIf="formalshow">
        <div class="list-wrap-modal">
          <div class="list-item" *ngFor="let item of formedmodalList">
            <div class="list-head">
              <div class="list-label">
                <a (click)="showModalDetail(item)">{{ item.headline }}</a>
              </div>
            </div>
            <div class="list-content">
              {{ item.summary }}
            </div>
            <div class="list-info">
              <div class="info-item"><icon name="notification"></icon>{{ item.media }}</div>
              <div class="info-item"><icon name="calendar"></icon>{{ item.date }}</div>
            </div>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Social Media" *ngIf="sosialshow">
        <div class="list-wrap-modal">
          <div class="list-item" *ngFor="let item of sosialmodalList">
            <div class="list-head">
              <div class="list-label">
                <a (click)="showModalDetail(item)">{{ item.headline }}</a>
              </div>
            </div>
            <div class="list-content">
              {{ item.summary }}
            </div>
            <div class="list-info">
              <div class="info-item"><icon name="notification"></icon>{{ item.media }}</div>
              <div class="info-item"><icon name="calendar"></icon>{{ item.date }}</div>
            </div>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
  <div footer>
    <app-button
      nz-button
      type="dashed"
      class="line-danger"
      icon="close-circle"
      text="cancel"
      (click)="handleCancel()"
    ></app-button>
  </div>
</modal>

<modal nz-modal width="70%" title="article detail" [visible]="modalDetail" closeable="false">
  <div content *ngFor="let item of modalDetailData">
    <div class="modal-body-content">
      <div class="section-label">
        <div class="modal-section label-col">
          <div class="modal-item">
            <div class="item-title">title</div>
            <span>{{ item.title }}</span>
          </div>
          <div class="modal-item">
            <div class="item-title">date</div>
            <span>{{ item.date }}</span>
          </div>
        </div>
        <div class="modal-section label-col">
          <div class="modal-item">
            <div class="item-title">media</div>
            <span>{{ item.media }}</span>
          </div>
        </div>
      </div>
      <div class="section-text">
        {{ item.summary }}
      </div>
    </div>
  </div>
  <div footer>
    <app-button
      nz-button
      type="dashed"
      class="line-info"
      icon="left-circle"
      text="back"
      (click)="handleBack()"
    ></app-button>
    <app-button
      nz-button
      type="dashed"
      class="line-danger"
      icon="close-circle"
      text="cancel"
      (click)="handleCancel()"
    ></app-button>
  </div>
</modal>

<nz-drawer
  [nzHeight]="'500px'"
  [nzMask]="false"
  [nzClosable]="false"
  nzPlacement="bottom"
  [nzVisible]="uploadfiletriger"
  [nzContent]="updateContent"
  [nzFooter]="updateFooter"
>
  <ng-template #updateContent>
    <div
      nz-row
      [nzGutter]="[
        { xs: 8, sm: 10, md: 12, lg: 12, xl: 16 },
        { xs: 8, sm: 10, md: 12, lg: 12, xl: 16 }
      ]"
    >
      <div nz-col nzSpan="24">
        <input type="file" (change)="onFileChange($event)" />
      </div>
      <div nz-col nzSpan="24">
        <nz-table #editRowTable nzSize="middle" nzTableLayout="fixed" nzBordered [nzData]="listOfData">
          <thead>
            <tr>
              <th>Name</th>
              <th>File</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of editRowTable.data" class="editable-row">
              <td nzEllipsis>{{ data.name }}</td>
              <td nzEllipsis>{{ data.file }}</td>
              <td>
                <a nz-popconfirm nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteRow(data.file)">Delete</a>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </ng-template>
  <ng-template #updateFooter>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="closeUpload()">Cancel</button>
      <button nz-button nzType="primary" (click)="renderFile()">Submit</button>
    </div>
  </ng-template>
</nz-drawer>
