<nz-modal
  [(nzVisible)]="visible"
  [nzTitle]="title"
  [nzFooter]="modalFooter"
  [nzClosable]="closeable"
  [nzWidth]="width"
>
  <ng-content select="[content]"></ng-content>

  <ng-template #modalFooter>
    <ng-content select="[footer]"></ng-content>
  </ng-template>
</nz-modal>
