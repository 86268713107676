import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from '@services/header.service';

import { pieOptions } from '@data/pieChart';
import { ChartComponent } from 'ng-apexcharts';
import * as Highcharts from 'highcharts';

import { ContentService } from "@services/content.service";
import { DataSeries } from './mockSeries';
import { money, flazz, tapcash, jenius } from '@core/mock/treemap';

import { localeData } from 'moment';
import { Subject, Subscription } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { takeUntil } from "rxjs/operators";

import { adValue } from './mockSeries';
import { FormControl } from '@angular/forms';
import * as XLSX from 'xlsx';
import { environment } from "../../../environments/environment";
@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss'],
})
export class BrandingComponent implements OnInit {
  baseColor = '#00bbf9';

  @ViewChild("chart") chart: ChartComponent;
  public tabsTitle = { title1: 'kkm', title2: 'kta', title3: 'giro', title4: 'umkm' };

  public adValueBrand = adValue;

  public treeMapOptions: any;

  public pieChart: any;

  public brandPerform1;
  public brandPerform2;

  public brandComp1;
  public brandComp2;

  Highcharts = Highcharts;
  loadews: boolean = false;

  volumeOptions = {};
  statusOptions = {};
  showbrandfeedbackmap: boolean = false;
  brand1: boolean = false;
  brand2: boolean = false;
  brandcomp1: boolean = false;
  brandcomp2: boolean = false;
  datalengthslide = [];
  subscription: Subscription;
  formalshow: boolean = false;
  sosialshow: boolean = false;
  sosialmodalList = [];
  formedmodalList = [];
  modalVisible = false;
  modalDetailData = [];
  modalDetail = false;
  subject = webSocket("wss://ifap.digivla.com:8091");
  // subject = webSocket('wss://echo.websocket.org')
  date = {
    edate: new Date(new Date()).toISOString().slice(0, 10),
    sdate: new Date(new Date(new Date().setDate(new Date().getDate() - 7))).toISOString().slice(0, 10)
  }
  dateformed = {
    end_date: new Date(new Date()).toISOString().slice(0, 10),
    start_date: new Date(new Date(new Date().setDate(new Date().getDate() - 7))).toISOString().slice(0, 10)
  }
  destroy$: Subject<boolean> = new Subject<boolean>();

  uploadfiletriger: boolean = true;
  listOfData = []
  clientName: string;
  constructor(private headerService: HeaderService,
    private contentSvc: ContentService) {
    for (let i = 0; i < environment.user.length; i++) {
      if (window.location.hostname.split('.')[0] == environment.user[i].id) {
        this.clientName = environment.user[i].clientname
      }
    }
  }

  // Modal Click
  showModalList() {
    this.modalVisible = true;
  }

  showModalDetail(item) {
    this.modalDetailData = [
      {
        title: item.headline,
        summary: item.summary,
        media: item.media,
        date: item.date
      }
    ];
    this.modalDetail = true;
    this.modalVisible = false;
  }

  handleCancel(): void {
    this.modalVisible = false;
    this.modalDetail = false;
  }

  deleteRow(data: any): void {
    const payload = {
      "doc_name": data
    }
    this.contentSvc.postdeletefile(payload).subscribe(
      res => {
        if (res) {
          this.getfileUser('delete')
        }
      }
    )
  }
  handleBack(): void {
    this.modalDetail = false;
    this.modalVisible = true;
  }

  ngOnInit() {
    this.headerService.setTitle('brand tracking');
    this.headerService.setColor(this.baseColor);
    this.getfileUser('load')
    this.loaddataEws();
    this.loaddummybrandassociation()
    // this.loadTreeMap();
    // this.loadbrandfeedbackmap()
    this.loaddummybrandfeedbackmap()
      this.loadAdvalue()
    this.subject.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.date['edate'] = data['end']
      this.date['sdate'] = data['start']
      this.dateformed['end_date'] = data['end']
      this.dateformed['start_date'] = data['start']
      this.getfileUser('load')
      this.loaddataEws();
      // this.loadTreeMap();
      // this.loadbrandfeedbackmap()
      this.loaddummybrandassociation()
      this.loaddummybrandfeedbackmap()
      this.loadAdvalue()
    });
  }

  editFile(): void {
    this.uploadfiletriger = true
  }
  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const payload = {
        "doc_name":this.clientName + '_' + file.name.substr(file.name.length - 18).split('.xlsx')[0],
        "client": this.clientName,
        "data_doc": {
          "Summary": jsonData.Summary
        }
      }
      this.contentSvc.postuploadfile(payload).subscribe(
        result => {
          if (result.message) {
            alert(result.message)
          } else {
            this.getfileUser('delete')
          }
        }
      )
    }
    reader.readAsBinaryString(file);
  }
  renderFile(): void {
    if (this.listOfData.length > 0) {
      this.uploadfiletriger = false;
      this.loadTreeMap(); 
    } else {
      alert('please upload file')
    }
  }
  getfileUser(type: any): void {
    this.contentSvc.getfileuser().subscribe(
      res => {
        this.listOfData = []
        if (res.data.length > 0) {
          if (type !== 'delete') this.uploadfiletriger = false
          res.data.forEach(element => {
            const data = {
              name: element.client,
              file: element.doc_name
            }
            this.listOfData.push(data)
          });
        } else {
          this.listOfData = []
        }
      }
    )
  }

  loadbrandfeedbackmap() {
    this.contentSvc.postbrandfeedbackmap(this.date).subscribe(
      result => {
        this.volumeOptions = {
          colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#9b5de5', '#ff6392'],
          series: result.data,
          chart: {
            type: 'bubble',
            backgroundColor: null,
            style: {
              fontFamily: 'Lato, sans-serif',
            },
          },

          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                format: '{point.name}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
            },
            bubble: {
              displayNegative: true,
              events: {
                click: (e) => {
                  this.loadcontentfeedbackmap(e.point.series.name, e.point.category)
                }
              }
            },
          },

          credits: {
            enabled: false,
          },

          exporting: {
            enabled: false,
          },

          legend: {
            enabled: true,
            itemStyle: {
              color: '#edf2f4',
              fontFamily: 'Lato, sans-serif',
            },
            itemHoverStyle: {
              color: '#495867',
            },
          },

          title: {
            text: undefined,
          },

          xAxis: {
            gridLineWidth: 1,
            gridLineDashStyle: 'longdash',
            type: 'datetime',
            title: {
              text: 'Mention Number',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: this.baseColor,
              },
            },
            labels: {
              align: 'center',
              format: '{value:%b}',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#edf2f4',
              },
            },
            categories: result.date,
          },

          yAxis: {
            startOnTick: false,
            endOnTick: false,
            gridLineWidth: 0,
            title: {
              text: 'Opinion Index',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: this.baseColor,
              },
            },
            labels: {
              format: '{value}',
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#edf2f4',
              },
            },
            plotLines: [
              {
                color: '#edf2f4',
                width: 1,
                value: 0,
                zIndex: 3,
              },
            ],
            plotBands: [
              {
                color: 'rgba(255, 107, 107, 0.3)',
                from: 0,
                to: -200,
              },
            ],
          },

          tooltip: {
            shared: false,
            animation: true,
            backgroundColor: 'transparent',
            borderWidth: 0,
            shadow: false,
            style: {
              color: '#edf2f4',
              padding: 0,
            },
            useHTML: true,
            formatter: function () {
              return (
                '<div class="tooltip">' +
                '<div class="tooltip-title">' +
                this.series.name +
                '</div>' +
                '<div class="tooltip-content">' +
                this.point.y +
                '</div>' +
                '</div>'
              );
            },
          },
        };
        this.showbrandfeedbackmap = true
      }
    )
  }
  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  loaddummybrandfeedbackmap(){
    this.contentSvc.postdummybrandfeedbackmap().subscribe(
      result => {
        if (result){
          const dataseries = []
          const date = []
          result.data.forEach(element => {
            let dataarr = []
              const obj = {
                "name": element.brand,
                "color": this.getRandomColor(),
                "marker": {
                  "symbol": "circle"
                }
              }
              for (const iterator of element.data) {
                dataarr.push(iterator.doc_count)
              }
              obj['data'] = dataarr
              dataseries.push(obj) 
          });
          result.data[0].data.forEach(element => {
            date.push(element.date)
          }); 
          this.volumeOptions = {
            colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#9b5de5', '#ff6392'],
            series: dataseries,
            chart: {
              type: 'bubble',
              backgroundColor: null,
              style: {
                fontFamily: 'Lato, sans-serif',
              },
            },
  
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  format: '{point.name}',
                  style: {
                    fontFamily: 'Lato, sans-serif',
                    color: '#edf2f4',
                  },
                },
              },
              bubble: {
                displayNegative: true,
                events: {
                  click: (e) => {
                    this.loadcontentfeedbackmap(e.point.series.name, e.point.category)
                  }
                }
              },
            },
  
            credits: {
              enabled: false,
            },
  
            exporting: {
              enabled: false,
            },
  
            legend: {
              enabled: true,
              itemStyle: {
                color: '#edf2f4',
                fontFamily: 'Lato, sans-serif',
              },
              itemHoverStyle: {
                color: '#495867',
              },
            },
  
            title: {
              text: undefined,
            },
  
            xAxis: {
              gridLineWidth: 1,
              gridLineDashStyle: 'longdash',
              type: 'datetime',
              title: {
                text: 'Mention Number',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: this.baseColor,
                },
              },
              labels: {
                align: 'center',
                format: '{value:%b}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
              categories: date,
            },
  
            yAxis: {
              startOnTick: false,
              endOnTick: false,
              gridLineWidth: 0,
              title: {
                text: 'Opinion Index',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: this.baseColor,
                },
              },
              labels: {
                format: '{value}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
              plotLines: [
                {
                  color: '#edf2f4',
                  width: 1,
                  value: 0,
                  zIndex: 3,
                },
              ],
              plotBands: [
                {
                  color: 'rgba(255, 107, 107, 0.3)',
                  from: 0,
                  to: -200,
                },
              ],
            },
  
            tooltip: {
              shared: false,
              animation: true,
              backgroundColor: 'transparent',
              borderWidth: 0,
              shadow: false,
              style: {
                color: '#edf2f4',
                padding: 0,
              },
              useHTML: true,
              formatter: function () {
                return (
                  '<div class="tooltip">' +
                  '<div class="tooltip-title">' +
                  this.series.name +
                  '</div>' +
                  '<div class="tooltip-content">' +
                  this.point.y +
                  '</div>' +
                  '</div>'
                );
              },
            },
          };
          this.showbrandfeedbackmap = true
        }
      }
    )
  }
  loadAdvalue():void{
    this.contentSvc.postadvalue().subscribe(
      result => {
        if (result){
          const datalabel = []
          const dataseries = []
          result.forEach(element => {
              datalabel.push(element.brand)
              dataseries.push(element.advlue)
          });
          this.pieChart = {
            series: dataseries,
            colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#9b5de5', '#ff6392', '#e76f51'],
            chart: {
              width: '100%',
              type: "pie",
              startRange: 0,
              events: {
                dataPointSelection: (event, chartContext, config) => {
                  this.loadDetailPie(datalabel[config.dataPointIndex])
                }
              }
            },
            labels: datalabel,
            plotOptions: {
              pie: {
                startAngle: 0,
                endAngle: 270,
              },
            },
      
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ['#edf2f4']
              }
            },
            tooltip: {
              fillSeriesColor: false,
              fillColors: '#36414c',
              y: {
                formatter: function (val) {
                  return 'IDR \n' + val.toLocaleString('en');
                },
                title: {
                  formatter: function (seriesName) {
                    return seriesName + ':';
                  },
                },
              },
            },
            stroke: pieOptions.stroke,
            legend: {
              show: true,
              position: 'bottom',
              floating: false,
              offsetX: 0,
              offsetY: 0,
              labels: pieOptions.legend.labels,
              markers: pieOptions.legend.markers,
              itemMargin: {
                horizontal: 5,
                vertical: 0
              }
            },
            responsive: [
              {
                breakpoint: 800,
                options: {
                  chart: {
                    height: 350,
                  },
                },
              },
              {
                breakpoint: 1025,
                options: {
                  chart: {
                    height: 400,
                  },
                },
              },
              {
                breakpoint: 1441,
                options: {
                  chart: {
                    height: '100%',
                  },
                },
              },
            ],
          };
        }
      }
    )
  }
  loadDetailPie(data):void{
    const payload = {
      "brand": data,
      "sdate": "2021-04-01",
    "edate": "2021-04-08",
      "page": 0
  }
  this.formedmodalList = []
  this.contentSvc.postadvalueDetail(payload).subscribe(
    result => {
      result.data.forEach(element => {
        const dataobj = {
          headline: element.title,
          summary: element.content,
          media: element.media_name,
          date: element.created_at
        }
        this.formedmodalList.push(dataobj)
      });
      this.formalshow = true
      this.sosialshow = false
      this.modalVisible = true   
    }
  )
  }
  loadcontentfeedbackmap(data, date) {
    this.formalshow = true
    this.sosialshow = false
    this.modalVisible = true
    this.date['brand'] = data
    this.date['edate'] = date
    this.date['sdate'] = date
    this.formedmodalList = [];
    this.sosialmodalList = []
    const obj = 'start_date='+date+'&end_date='+date+'&client=bni&page=0&size=100&brand='+data
    this.contentSvc.postdummycontent(obj).subscribe(
      result => {
        if (result){
                result.data.forEach(element => {
            if (element.platform === 'fb' || element.platform === 'yt' || element.platform === 'ig') {
              element['nameuse'] = element.user.name
              element['linkuse'] = element.user.post_url
            } else {
              element['nameuse'] = element.user.screen_name
              element['linkuse'] = 'twitter.com' + element.user.post_url
            }
            const dataobj = {
              headline: element.user.name,
              summary: element.text,
              media: element.platform,
              date: element.created_at
            }
            this.formedmodalList.push(dataobj)
          });
        }
      }
    )
    // this.contentSvc.postcontentbrand(this.date).subscribe(
    //   result => {
    //     if (result) {
    //       result.data.sosmed.data.forEach(element => {
    //         if (element.platform === 'fb' || element.platform === 'yt' || element.platform === 'ig') {
    //           element['nameuse'] = element.user.name
    //           element['linkuse'] = element.user.post_url
    //         } else {
    //           element['nameuse'] = element.user.screen_name
    //           element['linkuse'] = 'twitter.com' + element.user.post_url
    //         }
    //         if (!element['nameuse'].includes('link rel')) {
    //           const dataobj = {
    //             headline: element.user.name,
    //             summary: element.text,
    //             media: element.platform,
    //             date: element.created_at
    //           }
    //           this.sosialmodalList.push(dataobj)
    //         }
    //       });
    //       result.data.formed.data.forEach(element => {
    //         const dataobj = {
    //           headline: element.title,
    //           summary: element.content,
    //           media: element.media_name,
    //           date: element.created_at
    //         }
    //         this.formedmodalList.push(dataobj)
    //       });
    //     }
    //   }
    // )
  }

  // Brand Severity Status
  loaddataEws() {

    const yAxisLabels = {
      0: "Potential",
      5: "Emerging",
      10: "Current",
      15: "Crisis"
    };
    this.contentSvc.postEws(this.dateformed).subscribe(
      result => {
        if (result) {
          var category_id = [];

          for (let i = 0; i < result.data.length; i++) {
            var datesArr = [];
            for (let j = 0; j < result.data[i].dates.length; j++) {
              var positive = 0;
              var negative = 0;
              for (let k = 0; k < result.data[i].dates[j].tones.length; k++) {
                if (result.data[i].dates[j].tones[k].tonality > 0) {
                  positive = result.data[i].dates[j].tones[k].summary_by_tier;
                } else if (result.data[i].dates[j].tones[k].tonality < 0) {
                  negative = result.data[i].dates[j].tones[k].summary_by_tier;
                }
              }
              var val = positive - negative;
              if (val < 0) {
                val = 0 - val;
                if (val > 15) {
                  val = 15;
                }
              } else if (negative > 0) {
                val = 1;
              } else {
                val = 0;
              }
              datesArr.push(val);
            }
            category_id.push({
              name: result.data[i].category_id,
              data: datesArr,
              type: "spline"
            });
          }
          var xAxisChart = [];
          for (let i = 0; i < result.data.length; i++) {
            for (let j = 0; j < result.data[i].dates.length; j++) {
              var a = xAxisChart.indexOf(result.data[i].dates[j].date);
              if (a < 0) {
                xAxisChart.push(result.data[i].dates[j].date);
              }
            }
          }
          this.statusOptions = {
            colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#9b5de5', '#ff6392'],
            series: category_id,
            chart: {
              type: 'line',
              backgroundColor: null,
              style: {
                fontFamily: 'Lato, sans-serif',
                color: '#edf2f4',
              },
            },

            title: {
              text: null,
            },

            credits: {
              enabled: false,
            },

            exporting: {
              enabled: false,
            },

            plotOptions: {
              series: {
                cursor: "pointer",
                allowPointSelect: true,
                point: {
                  events: {
                    click: (e) => {
                      this.loadcontentews(e.point.category, e.point.series.name)
                    }
                  }
                },
                marker: {
                  symbol: 'circle',
                  lineColor: null,
                  lineWidth: 2,
                },
              }
            },

            yAxis: {
              max: 15,
              min: 0,
              title: { text: null },
              labels: {
                formatter: function () {
                  var value = yAxisLabels[this.value];
                  return value !== "undefined" ? value : this.value;
                },
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              }
            },
            xAxis: {
              gridLineWidth: 1,
              gridLineDashStyle: 'longdash',
              categories: xAxisChart,
              startOnTick: true,
              title: {
                text: null,
              },
              labels: {
                align: 'center',
                format: '{value:%b}',
                style: {
                  fontFamily: 'Lato, sans-serif',
                  color: '#edf2f4',
                },
              },
            },

            tooltip: {
              shared: true,
              animation: true,
              backgroundColor: 'transparent',
              borderWidth: 0,
              shadow: false,
              style: {
                color: '#edf2f4',
                fontFamily: 'Lato, sans-serif',
              },
              useHTML: true,
              headerFormat:
                '<div class="tooltip share"><div class="tooltip-title"> {point.key} </div>',
              pointFormat:
                '<div class="tooltip-content share"><div class="series-name""><span class="dot" style="background-color: {series.color};"></span>{series.name}:<span class="series-value">{point.y}</span></div></div>',
              footerFormat: '</div>',
            },

            legend: {
              enabled: true,
              itemStyle: {
                color: '#edf2f4',
                fontFamily: 'Lato, sans-serif',
              },
              itemHoverStyle: {
                color: '#495867',
              },
            },
          };
        }
        this.loadews = true
      }
    )
  }

  loadcontentews(date, data) {
    this.formedmodalList = [];
    this.formalshow = true
    this.sosialshow = false
    this.modalVisible = true
    const datause = {
      category_id: data,
      end_date: date,
      start_date: date,
    }
    this.contentSvc.postcontentEws(datause).subscribe(
      result => {
        result.data.forEach(element => {
          const dataobj = {
            headline: element.title,
            summary: element.content,
            media: element.media_name,
            date: element.created_at
          }
          this.formedmodalList.push(dataobj)
        });
      }
    )
  }

  // new chart
  loadTreeMap() {
    this.contentSvc.getfileuser().subscribe(
      res => {
        const datanameDoc = []
        res.data.forEach(element => {
            datanameDoc.push(element.doc_name)
        });
        const payload = {
          doc_names:datanameDoc
        }
        this.contentSvc.posttoptenFile(payload).subscribe(
          item => {
            this.treeMapOptions = {
              series: item,
              colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#9b5de5', '#ff6392'],
              plotOptions: {
                treemap: {
                  distributed: false,
                  enableShades: false,
                  useFillColorAsStroke: false,
                  min: 40,
                  max: 40
                },
              },
              fill: {
                opacity: 1,
                type: 'solid',
              },
              legend: {
                show: true,
                fontSize: '14px',
                fontFamily: 'Lato, sans-serif',
                fontWeight: 400,
                offsetX: 0,
                offsetY: 0,
                labels: {
                  colors: '#edf2f4',
                  useSeriesColors: false,
                },
                itemMargin: {
                  horizontal: 5,
                  vertical: 10,
                },
              },
              chart: {
                width: '100%',
                height: 350,
                type: 'treemap',
                toolbar: {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    customIcons: [],
                  },
                },
              },
              dataLabels: {
                enabled: true,
                enabledOnSeries: undefined,
                formatter: function (val, opts) {
                  return val;
                },
                textAnchor: 'middle',
                distributed: true,
                offsetX: 0,
                offsetY: 0,
                style: {
                  fontSize: '14px',
                  fontFamily: 'Lato, sans-serif',
                  fontWeight: 'bold',
                  colors: ['#36414c'],  
                },
              },
              responsive: [
                {
                  breakpoint: 800,
                  options: {
                    chart: {
                      height: 250,
                    },
                  },
                },
                {
                  breakpoint: 1025,
                  options: {
                    chart: {
                      height: 350,
                    },
                  },
                },
                {
                  breakpoint: 1441,
                  options: {
                    chart: {
                      height: 350,
                    },
                  },
                },
                {
                  breakpoint: 2561,
                  options: {
                    chart: {
                      height: 400,
                    },
                  },
                },
                {
                  breakpoint: 2561,
                  options: {
                    chart: {
                      height: 700,
                    },
                  },
                },
              ],
            };      
          }
        )
      }
    )
  }

  loaddummybrandassociation(){
    this.contentSvc.postdummybrandassociation().subscribe(
      result => {
        if (result){
          let dataseries = []
          const filterseries = []
          Object.keys(result.data).forEach((key) => {
            const datapush = []
            const obj = {
              name : key,
            }
            for (const iterator of result.data[key]) {
              const objpush = {
                x : iterator.key,
                y: iterator.doc_count
              }
              datapush.push(objpush)
            }
            obj['data'] = datapush

            dataseries.push(obj)
          });   
          dataseries.forEach(item => {
            if (item.data.length > 0){
              filterseries.push(item)
            }
          })
          this.treeMapOptions = {
            series: filterseries,
            colors: ['#00bbf9', '#06d6a0', '#ff6b6b', '#ffe66d', '#edf2f4', '#9b5de5', '#ff6392'],
            plotOptions: {
              treemap: {
                distributed: false,
                enableShades: false,
                useFillColorAsStroke: false,
                min: 40,
                max: 40
              },
            },
            fill: {
              opacity: 1,
              type: 'solid',
            },
            legend: {
              show: true,
              fontSize: '14px',
              fontFamily: 'Lato, sans-serif',
              fontWeight: 400,
              offsetX: 0,
              offsetY: 0,
              labels: {
                colors: '#edf2f4',
                useSeriesColors: false,
              },
              itemMargin: {
                horizontal: 5,
                vertical: 10,
              },
            },
            chart: {
              width: '100%',
              height: 350,
              type: 'treemap',
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: false,
                  selection: false,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: false,
                  customIcons: [],
                },
              },
            },
            dataLabels: {
              enabled: true,
              enabledOnSeries: undefined,
              formatter: function (val, opts) {
                return val;
              },
              textAnchor: 'middle',
              distributed: true,
              offsetX: 0,
              offsetY: 0,
              style: {
                fontSize: '14px',
                fontFamily: 'Lato, sans-serif',
                fontWeight: 'bold',
                colors: ['#36414c'],  
              },
            },
            responsive: [
              {
                breakpoint: 800,
                options: {
                  chart: {
                    height: 250,
                  },
                },
              },
              {
                breakpoint: 1025,
                options: {
                  chart: {
                    height: 350,
                  },
                },
              },
              {
                breakpoint: 1441,
                options: {
                  chart: {
                    height: 350,
                  },
                },
              },
              {
                breakpoint: 2561,
                options: {
                  chart: {
                    height: 400,
                  },
                },
              },
              {
                breakpoint: 2561,
                options: {
                  chart: {
                    height: 700,
                  },
                },
              },
            ],
          };
          console.log(this.treeMapOptions);
             
        }
      }
    )
  }

  closeUpload() {
    this.uploadfiletriger = false;
  }
}
