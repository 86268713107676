export const pieOptions = {
  chart: {
    width: '100%',
    height: 215,
    type: 'donut',
  },
  colors: ['#06d6a0', '#00bbf9', '#ff6b6b','#ffe66d'],
  plotOptions: {
    pie: {
      startAngle: 0,
      expandOnClick: false,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        offset: 0,
        minAngleToShowLabel: 10,
      },
      donut: {
        size: '65%',
        background: 'transparent',
        labels: {
          show: true,
          name: {
            show: true,
            fontSize: '22px',
            fontFamily: 'Lato, sans-serif',
            fontWeight: 600,
            color: '#edf2f4',
            offsetY: 0,
            formatter: function (val) {
              return val;
            },
          },
          value: {
            show: false,
            fontSize: '16px',
            fontFamily: 'Lato, sans-serif',
            fontWeight: 400,
            color: '#edf2f4',
            offsetY: 5,
            formatter: function (val) {
              return val;
            },
          },
          total: {
            show: true,
            showAlways: true,
            label: 'brand 1',
            fontSize: '15px',
            fontFamily: 'Kanit, sans-serif',
            fontWeight: 'normal',
            color: '#edf2f4',
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);
            },
          },
        },
      },
    },
  },

  stroke: {
    width: 4,
    colors: ['#36414c'],
  },

  legend: {
    show: true,
    position: 'bottom',
    labels: {
      colors: '#edf2f4',
      useSeriesColors: false,
    },

    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: '#36414c',
      fillColors: ['#06d6a0', '#00bbf9', '#ff6b6b'],
      radius: 12,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },

    itemMargin: {
      horizontal: 3,
      vertical: 0,
    },
    tooltipHoverFormatter: function (val, opts) {
      return val;
    },
  },

  dataLabels: {
    enabled: true,
  },

  fill: {
    type: 'solid',
    opacity: 1,
    colors: ['#06d6a0', '#00bbf9', '#ff6b6b'],
  },

  tooltip: {
    enabled: true,
    enabledOnSeries: undefined,
    shared: false,
    followCursor: false,
    intersect: false,
    inverseOrder: false,
    custom: undefined,
    fillSeriesColor: false,
    fillColors: '#36414c',
    style: {
      fontSize: '14px',
      fontFamily: 'Lato, sans-serif',
      color: '#36414c',
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
    x: {
      show: true,
      format: 'dd MMM',
      formatter: undefined,
    },
    y: {
      formatter: undefined,
      title: {
        formatter: (seriesName) => seriesName,
      },
    },
    z: {
      formatter: undefined,
      title: 'Size: ',
    },
    marker: {
      show: true,
      fillSeriesColor: ['#06d6a0', '#00bbf9', '#ff6b6b'],
    },
    items: {
      display: 'flex',
    },
    fixed: {
      enabled: false,
    },
  },
  xaxis: {
    type: 'category',
  },

  responsive: [
    {
      breakpoint: 400,
      options: {
        chart: {
          width: 200,
          height: 200,
        },
        dataLabels: {
          enabled: false
        }
      },
    },
    {
      breakpoint: 1025,
      options: {
        chart: {
          width: 120,
          height: 150,
        },
        dataLabels: {
          enabled: false
        }
      },
    },
    {
      breakpoint: 1441,
      options: {
        chart: {
          width: 300,
          height: 300,
        },
        dataLabels: {
          enabled: true
        }
      },
    },
    {
      breakpoint: 1921,
      options: {
        chart: {
          width: 250,
          height: 250,
        },
        dataLabels: {
          enabled: true
        }
      },
    },
    {
      breakpoint: 2561,
      options: {
        chart: {
          width: 400,
          height: 400,
        },
        dataLabels: {
          enabled: true
        }
      },
    },
  ],
};