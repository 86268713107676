<nz-card [nzSize]="size" [nzBordered]="false" [nzTitle]="cardTitle" [nzExtra]="extraTemplate">
  <ng-template #cardTitle>
    <span [ngStyle]="{ color: color }">
      {{ title }}
    </span>
  </ng-template>
  <ng-content></ng-content>
  <ng-template #extraTemplate>
    <ng-content select="[extra]"></ng-content>
  </ng-template>
</nz-card>
