<div
  nz-row
  [nzGutter]="[
    { xs: 8, sm: 10, md: 12, lg: 10, xl: 12, xxl: 16 },
    { xs: 8, sm: 10, md: 12, lg: 10, xl: 12, xxl: 16 }
  ]"
>
  <div nz-col nzXs="24" nzMd="24" nzLg="17" nzXl="18">
    <div
      nz-row
      [nzGutter]="[
        { xs: 8, sm: 10, md: 12, lg: 10, xl: 12, xxl: 16 },
        { xs: 8, sm: 10, md: 12, lg: 10, xl: 12, xxl: 16 }
      ]"
    >
      <div nz-col nzXs="24" nzMd="12" nzLg="14" nzXl="16">
        <card nz-card title="customer feedback map" [color]="baseColor">
          <div class="chart-component" *ngIf="showfeedback">
            <highcharts-chart class="feedback" [Highcharts]="Highcharts" [options]="feedbackOptions"></highcharts-chart>
          </div>
        </card>
      </div>
      <div nz-col nzXs="24" nzMd="12" nzLg="10" nzXl="8">
        <card nz-card title="trending customer feedback" [color]="baseColor">
          <div class="chart-component" *ngIf="showwordcloud">
            <highcharts-chart
              class="feedback"
              [Highcharts]="Highcharts"
              [options]="wordcloudOptions"
            ></highcharts-chart>
          </div>
        </card>
      </div>
      <div nz-col nzXs="24" nzMd="24" nzLg="24" nzXl="24">
        <card nz-card title="customer opinion index" [color]="baseColor"
          ><div class="chart-component" *ngIf="showopinion">
            <highcharts-chart
              class="opinion"
              [Highcharts]="Highcharts"
              [options]="opinionOptions"
            ></highcharts-chart></div
        ></card>
      </div>
    </div>
  </div>
  <div nz-col nzXs="24" nzMd="24" nzLg="7" nzXl="6">
    <div
      nz-row
      [nzGutter]="[
        { xs: 8, sm: 10, md: 12, lg: 12, xl: 16 },
        { xs: 8, sm: 10, md: 12, lg: 12, xl: 16 }
      ]"
    >
      <div nz-col nzXs="12" nzMd="12" nzLg="24" nzXl="24">
        <card nz-card title="customer satisfaction point" [color]="baseColor">
          <div class="list-wrap">
            <div class="list-item" *ngFor="let item of listdatacustomersatisfac">
              <div class="list-head">
                <div class="list-label">
                  <icon class="label-icon" name="file-protect" theme="outline" size="15px" color="#edf2f4"></icon>
                  <a>{{ item.nameuse }}</a>
                </div>
                <div class="list-icon">
                  <icon name="fund" theme="fill" size="15px" [color]="baseColor"></icon>
                  {{ item.engagement }}
                </div>
              </div>
              <div class="list-content">
                <span nz-tooltip nzTooltipPlacement="left" [nzTooltipTitle]="item.text"> {{ item.text }} </span>
              </div>
              <div class="list-info">
                <app-tag class="tag-success" icon="plus-circle" [text]="item.opinion_index" *ngIf="item.opinion_index > 0"></app-tag>
                <app-tag class="tag-danger" icon="minus-circle" [text]="item.opinion_index" *ngIf="item.opinion_index < 0"></app-tag>
              </div>
            </div>
          </div>
        </card>
      </div>
      <div nz-col nzXs="12" nzMd="12" nzLg="24" nzXl="24">
        <card nz-card title="customer pain point" [color]="baseColor">
          <div class="list-wrap">
            <div class="list-item" *ngFor="let item of listdatacustomerpainpoint">
              <div class="list-head">
                <div class="list-label">
                  <icon class="label-icon" name="file-protect" theme="outline" size="15px" color="#edf2f4"></icon>
                  <a>{{ item.nameuse }}</a>
                </div>
                <div class="list-icon">
                  <icon name="fund" theme="fill" size="15px" [color]="baseColor"></icon>
                  {{ item.likes_count }}
                </div>
              </div>
              <div class="list-content">
                <span nz-tooltip nzTooltipPlacement="left" [nzTooltipTitle]="item.text"> {{ item.text }} </span>
              </div>
              <div class="list-info">
                <app-tag class="tag-success" icon="plus-circle" [text]="item.opinion_index" *ngIf="item.opinion_index > 0"></app-tag>
                <app-tag class="tag-danger" icon="minus-circle" [text]="item.opinion_index" *ngIf="item.opinion_index < 0"></app-tag>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>

<modal nz-modal width="70%" title="article list" [visible]="modalVisible" closeable="false">
  <div content>
    <nz-tabset nzSize="small" nzTabPosition="top">
      <nz-tab nzTitle="Formal Media" *ngIf="formalshow">
        <div class="list-wrap-modal">
          <div class="list-item" *ngFor="let item of formedmodalList">
            <div class="list-head">
              <div class="list-label">
                <a (click)="showModalDetail(item)">{{ item.headline }}</a>
              </div>
            </div>
            <div class="list-content">{{ item.summary }}</div>
            <div class="list-info">
              <div class="info-item"><icon name="notification"></icon>{{ item.media }}</div>
              <div class="info-item"><icon name="calendar"></icon>{{ item.date }}</div>
            </div>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Social Media" *ngIf="sosialshow">
        <div class="list-wrap-modal">
          <div class="list-item" *ngFor="let item of sosialmodalList">
            <div class="list-head">
              <div class="list-label">
                <a (click)="showModalDetail(item)">{{ item.headline }}</a>
              </div>
            </div>
            <div class="list-content">{{ item.summary }}</div>
            <div class="list-info">
              <div class="info-item"><icon name="notification"></icon>{{ item.media }}</div>
              <div class="info-item"><icon name="calendar"></icon>{{ item.date }}</div>
            </div>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
  <div footer>
    <app-button
      nz-button
      type="dashed"
      class="line-danger"
      icon="close-circle"
      text="cancel"
      (click)="handleCancel()"
    ></app-button>
  </div>
</modal>

<modal nz-modal width="70%" title="article detail" [visible]="modalDetail" closeable="false">
  <div content *ngFor="let item of modalDetailData">
    <div class="modal-body-content">
      <div class="section-label">
        <div class="modal-section label-col">
          <div class="modal-item">
            <div class="item-title">title</div>
            <span>{{ item.title }}</span>
          </div>
          <div class="modal-item">
            <div class="item-title">date</div>
            <span>{{ item.date }}</span>
          </div>
        </div>
        <div class="modal-section label-col">
          <div class="modal-item">
            <div class="item-title">media</div>
            <span>{{ item.media }}</span>
          </div>
        </div>
      </div>
      <div class="section-text">{{ item.summary }}</div>
    </div>
  </div>
  <div footer>
    <app-button
      nz-button
      type="dashed"
      class="line-info"
      icon="left-circle"
      text="back"
      (click)="handleBack()"
    ></app-button>
    <app-button
      nz-button
      type="dashed"
      class="line-danger"
      icon="close-circle"
      text="cancel"
      (click)="handleCancel()"
    ></app-button>
  </div>
</modal>
