import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from '@core/layout/layout.component';
import { CustomerComponent } from '@pages/customer/customer.component';
import { MediaComponent } from '@pages/media/media.component';
import { BrandingComponent } from '@pages/branding/branding.component';
import { CompetitorComponent } from '@pages/competitor/competitor.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'customer'},
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'customer',
        component: CustomerComponent,
        pathMatch: 'full',
      },
      {
        path: 'media',
        component: MediaComponent,
      },
      {
        path: 'brand',
        component: BrandingComponent,
      },
      {
        path: 'competitor',
        component: CompetitorComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
