import { Component, OnInit, Input } from '@angular/core';
import { HeaderService } from '@services/header.service';
import { endOfDay, endOfWeek, endOfMonth, endOfYear } from 'date-fns';
import { ConfigsocketService } from "@services/configsocket.service";

import { Subscription } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { RoutesItem } from '@core/data/routes';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {

  @Input() imgPath = '../../../assets/img';
  headTitle = '';
  headColor = '';

  menus = RoutesItem;
  isMode = 'inline'

  isMenu: boolean = false;
  
  ranges1 = {
    Today: [new Date(new Date()).toISOString().slice(0, 10),new Date(new Date()).toISOString().slice(0, 10)],
    Yerterday:[new Date(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString().slice(0, 10),new Date(new Date()).toISOString().slice(0, 10)],
    Week: [new Date(new Date(new Date().setDate(new Date().getDate() - 7))).toISOString().slice(0, 10),new Date(new Date()).toISOString().slice(0, 10)],
    Month: [new Date(new Date(new Date().setDate(new Date().getDate() - 30))).toISOString().slice(0, 10),new Date(new Date()).toISOString().slice(0, 10)],
    Year: [new Date(new Date(new Date().setDate(new Date().getDate() - 360))).toISOString().slice(0, 10),new Date(new Date()).toISOString().slice(0, 10)],
  };

  subscription: Subscription;
  subject = webSocket("wss://ifap.digivla.com:8091");
  // subject = webSocket('wss://echo.websocket.org')
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private headerService: HeaderService,private chatService: ConfigsocketService) {}

  ngOnInit(): void {
    this.headerService.title.subscribe((headTitle) => {
      this.headTitle = headTitle;
    });

    this.headerService.color.subscribe((headColor) => {
      this.headColor = headColor;
    });
    this.subject.pipe(takeUntil(this.destroy$)).subscribe((data) => {
    });
  }

  onChange(result: Date[]): void {
    this.subject.next({
      start: new Date (result[0]).toISOString().split('T')[0],
      end: new Date (result[1]).toISOString().split('T')[0],
    });
  }
}
