<apx-chart
  class="pie-chart"
  [series]="props.series"
  [labels]="chartOptions.labels"
  [chart]="chartOptions.chart"
  [xaxis]="chartOptions.xaxis"
  [yaxis]="chartOptions.yaxis"
  [fill]="chartOptions.fill"
  [colors]="chartOptions.colors"
  [stroke]="chartOptions.stroke"
  [dataLabels]="chartOptions.dataLabels"
  [plotOptions]="chartOptions.plotOptions"
  [tooltip]="chartOptions.tooltip"
  [title]="chartOptions.title"
  [legend]="chartOptions.legend"
  [responsive]="chartOptions.responsive"
></apx-chart>
