import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnInit {
  isMaintenance: boolean;
  isUnderMaintenancePage: boolean;
  constructor(
    private http: HttpClient,
  ) {
  }

  ngOnInit(): void {
  }
  handleError(err: HttpErrorResponse): void {
    switch (err.status) {
      case 0:
        break;
      case 500:
      case 404:
        break;
      case 401:
        break;
      default:
    }
  }
  getData(url: string, header?: any): Observable<any> {
    return new Observable(observer => {
      const httpParams = new HttpParams();
      this.http.get(url, {
        headers: header,
        observe: 'response',
        params: httpParams
      }).subscribe(
        res => {
          observer.next(res.body);
        },
        (err: HttpErrorResponse) => {
          this.handleError(err);
          observer.error(err);
        },
        () => {
          observer.complete();
        }
      );

    });
  }
  postData(url: string, body = '', header?: any): Observable<any> {
    return new Observable(observer => {
      this.http.post(url, body, {
        headers: header,
        observe: 'response'
      }).subscribe(
        res => {
          observer.next(res.body);
        },
        (err: HttpErrorResponse) => {
          this.handleError(err);
          observer.error(err);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

}
