<nz-layout class="default">
  <nz-header>
    <div class="inner-header">
      <h3 class="header-title" [ngStyle]="{ color: headColor }">
        {{ headTitle }}
      </h3>
      <div class="header-item">
        <div class="head-logo">
          <img src="{{ imgPath }}/logo-dark.png" alt="logo" />
        </div>
        <nz-range-picker
          nzSize="small"
          nzBorderless
          [nzRanges]="ranges1"
          [nzDateRender]="tplRender"
          [nzPlaceHolder]="['Start Date', 'End Date']"
          nzFormat="dd MMM yyyy"
          ngModel
          (ngModelChange)="onChange($event)"
        ></nz-range-picker>
      </div>
    </div>
  </nz-header>
  <nz-content>
    <router-outlet></router-outlet>
  </nz-content>
  <div class="mobile-menu">
    <div class="inner-menu">
      <ng-container *ngTemplateOutlet="mobileTpl; context: { $implicit: menus }"></ng-container>
      <ng-template #mobileTpl let-menus>
        <ng-container *ngFor="let menu of menus">
          <div class="menu-item" *ngIf="!menu.children">
            <a [routerLink]="menu.link" nzMatchRouter>
              <i nz-icon [nzType]="menu.icon"></i>
            </a>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</nz-layout>

<ng-template #tplRender let-current>
  <div class="ant-picker-cell-inner" [class.border]="current.getDate() === 1">{{ current.getDate() }}</div>
</ng-template>
